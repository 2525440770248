import { setOrderPartProviderOffers } from '$api/evoAPIs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, Row, Tabs, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from '$components/Form'

import RequestedPartsTable from '../RequestedPartsTable'
import ProviderOfferForm from './ProviderOfferForm'
import ProviderOffersList from './ProviderOffersList'
import FollowUpTreatmentsView from '$components/FollowUpTreatmentsView'
import { StyledForm } from './styledComponents'

const CreateOfferDetailView = ({
  back,
  orderPartsListId,
  orderPart,
  orderPartId,
  currentStep,
  subOrders,
  setOrderPartsFormValues,
  orderPartsFormValues,
  deleteRule,
  deleteProviderOffer,
}) => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()

  const [selectedProviderOfferKey, setSelectedProviderOfferKey] = useState(
    orderPart.provider_offers[0]?.offer_id,
  )

  const hasProviderOffers =
    orderPart.provider_offers && orderPart.provider_offers.length > 0

  const [providerOffers, setProviderOffers] = useState(
    hasProviderOffers
      ? orderPart.provider_offers.map((offer) => ({
          ...offer,
          price_rules: Object.fromEntries(
            offer.price_rules.map((rule) => [rule.id, rule]),
          ),
          production_time_rules: Object.fromEntries(
            offer.production_time_rules.map((rule) => [rule.id, rule]),
          ),
        }))
      : subOrders
          .filter(
            (subOrder) =>
              subOrder.orderpart_quantity[orderPartId] &&
              subOrder.orderpart_quantity[orderPartId] > 0,
          )
          .map(
            ({
              orderpart_quantity,
              allow_partial_delivery,
              allow_other_suppliers,
              allow_subcontractors,
              desired_delivery_date,
            }) => ({
              offer_id: crypto.randomUUID(), // preliminary id
              amount: orderpart_quantity[orderPartId],
              allow_other_suppliers: allow_other_suppliers || false,
              allow_partial_delivery,
              allow_subcontractors,
              desired_delivery_date,
            }),
          ),
  )

  const [invalidProviderOffers, setInvalidProviderOffers] = useState([])

  const setProviderOffersMutation = useMutation({
    mutationFn: setOrderPartProviderOffers,
    onSuccess: () => {
      queryClient.invalidateQueries(['allorderedpartslists'])
    },
  })

  const onFinish = (values) => {
    setProviderOffersMutation.mutate(
      {
        order_part_id: orderPartId,
        provider_offers: Object.values(
          values[orderPartId]['provider_offers'],
        ).map((offer) => ({
          ...offer,
          price_rules: offer.price_rules && Object.values(offer.price_rules),
          production_time_rules:
            offer.production_time_rules &&
            Object.values(offer.production_time_rules),
        })),
      },
      {
        onSuccess: () => {
          messageApi.success('Successfully saved offer!')
          back()
        },
        onError: () => messageApi.error('Could not save offer!'),
      },
    )
  }

  const onFinishFailed = ({ errorFields }) => {
    messageApi.error(t('received_requests.missing_offer_data'))
    const newInvalidSubOrders = errorFields.map((errorField) => {
      const [_orderPartId, _, subOrderId] = errorField.name
      return subOrderId
    })
    setInvalidProviderOffers([...invalidProviderOffers, ...newInvalidSubOrders])
  }

  const resetInvalidProviderOffers = () => setInvalidProviderOffers([])

  return (
    <>
      {contextHolder}
      <h2 style={{ margin: '15px 0' }}>
        {t('received_requests.place_your_offer')}
      </h2>
      <h4>{t('received_requests.current_part')}</h4>
      <div style={{ margin: '30px 0' }}>
        <RequestedPartsTable
          orderParts={[orderPart]}
          currentStep={currentStep}
          orderPartsFormValues={orderPartsFormValues}
        />
      </div>
      <FollowUpTreatmentsView orderPart={orderPart} />
      <StyledForm
        form={form}
        name={orderPartsListId}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          [orderPartId]: {
            provider_offers: Object.fromEntries(
              providerOffers.map((offer) => [offer.offer_id, { ...offer }]),
            ),
          },
        }}
        onValuesChange={resetInvalidProviderOffers}
      >
        <Row gutter="32">
          <Col span="8">
            <ProviderOffersList
              orderPartId={orderPartId}
              providerOffers={providerOffers.map((offer) => ({
                key: offer.offer_id,
                ...offer,
              }))}
              setProviderOffers={setProviderOffers}
              selectedKey={selectedProviderOfferKey}
              setSelectedKey={setSelectedProviderOfferKey}
              providerOffersStored={hasProviderOffers}
              invalidProviderOffers={invalidProviderOffers}
              deleteProviderOffer={(props) => {
                // 1. ensure that copy of form values is set and up to date
                setOrderPartsFormValues(form.getFieldValue())
                // 2. delete the rule from said copy
                deleteProviderOffer({ orderPartsListId, ...props })
              }}
            />
          </Col>
          <Col span="16">
            <div>
              <Tabs
                activeKey={selectedProviderOfferKey}
                items={providerOffers.map((providerOffer) => ({
                  key: providerOffer.offer_id,
                  children: (
                    <ProviderOfferForm
                      providerOffer={providerOffer}
                      orderPartId={orderPartId}
                      resetInvalidProviderOffers={resetInvalidProviderOffers}
                      deleteRule={(props) => {
                        // 1. ensure that copy of form values is set and up to date
                        setOrderPartsFormValues(form.getFieldValue())
                        // 2. delete the rule from said copy
                        deleteRule({ orderPartsListId, ...props })
                      }}
                    />
                  ),
                  forceRender: true,
                }))}
                renderTabBar={() => <></>}
              />
            </div>
          </Col>
        </Row>
        <Row justify={'end'} align={'center'} style={{ marginTop: 50 }}>
          <div>
            <Button onClick={back} style={{ marginRight: '15px' }}>
              {t('button.back')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('received_requests.save_offer')}
            </Button>
          </div>
        </Row>
      </StyledForm>
    </>
  )
}

export default CreateOfferDetailView
