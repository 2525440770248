import {
  useMutation,
  useQuery,
  useQueryClient,
  useQueries,
} from '@tanstack/react-query'
import { App, Button, Popconfirm } from 'antd'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { putStatusAttributes, fetchPart } from '$api/client'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import {
  fetchAllServices,
  postOrder,
  postOrderList,
  postPartsConsolidation,
} from '$api/evoAPIs'
import StatusPage from '../Defaultwizard/StatusPage'
import StepThreeSummaryOrderPart from './StepThreeSummaryOrderPart'
import {
  StyledStepButtons,
  StyledSteps,
} from '../Defaultwizard/styledComponents'
import { CenteredSpinner } from '$components/Spinner'
import StepOneSelection from '../Defaultwizard/StepOneSelection'
import StepTwoConfigOrderPart from './StepTwoConfigOrderPart'
import { comparePartAttributes } from './comparePartAttributes.js'
import { ORDERPARTS_STORAGE_KEY } from '$constants'
import { useCompany } from '$context/user'

/** A default shape for your wizard data. */
const defaultWizardData = {
  bookableParts: null,
  accumulatedFormData: [],
  orderPartsListData: null,
  configFormData: {
    // default for partial_delivery
    allow_partial_delivery: false,
    allow_subcontractors: false,
    allow_other_suppliers: false,
    subOrders: [],
  },
  currentStepPath: 'selection',
}

/** A small helper to compare arrays/objects as JSON. */
const jsonEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

/**
 * A hook to read wizard data from local storage on mount,
 * and write it back on changes.
 */
const useLocalWizardState = (companyData) => {
  // Lazy initialization: read local storage only once, on mount
  const [wizardData, setWizardData] = useState(() => {
    const raw = localStorage.getItem(ORDERPARTS_STORAGE_KEY)

    defaultWizardData.configFormData.delivery_address = { ...companyData }
    defaultWizardData.configFormData.billing_address = { ...companyData }
    if (raw) {
      try {
        return JSON.parse(raw)
      } catch (err) {
        throw new Error(`${err.response?.status || ''}`)
      }
    }
    return defaultWizardData
  })

  // Write to local storage on each update
  useEffect(() => {
    if (
      wizardData.bookableParts?.bookableParts.length > 0 ||
      wizardData.bookableParts?.notBookableParts.length > 0
    ) {
      localStorage.setItem(ORDERPARTS_STORAGE_KEY, JSON.stringify(wizardData))
    }
  }, [wizardData])

  return [wizardData, setWizardData]
}

const Orderparts = () => {
  const {
    bookedServiceStatus,
    selectedParts,
    setSelectedParts,
    setCurrent,
    activeGroupName,
    orderToRepeat,
    setOrderToRepeat,
  } = useOutletContext()

  const { t } = useTranslation()
  const { message } = App.useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const companyData = useCompany()

  /** 1) Our “wizardData” object in state. */
  const [wizardData, setWizardData] = useLocalWizardState(companyData)
  const {
    bookableParts,
    accumulatedFormData,
    orderPartsListData,
    currentStepPath,
  } = wizardData

  const storedWizardParts = [
    ...(bookableParts?.bookableParts ?? []),
    ...(bookableParts?.notBookableParts ?? []),
  ]

  // Some ephemeral states for UI
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [configState, setConfigState] = useState(true)

  /** 2) Detect if we have "continued" wizard data from local storage. */
  const isContinuedOrder = useMemo(() => {
    if (!storedWizardParts) {
      return false
    }
    const hasParts = storedWizardParts?.length > 0
    const isFresh = searchParams.get('fresh')
    return hasParts && !isFresh
  }, [storedWizardParts, searchParams])

  /** 3) If it’s a continued order, we want to fetch the “latest” parts to compare. */
  const partIds = useMemo(() => {
    if (isContinuedOrder) {
      return storedWizardParts.map((p) => p.id).filter(Boolean)
    }
    return []
  }, [storedWizardParts, isContinuedOrder])

  const { data: servicesData } = useQuery(['services'], fetchAllServices)

  const partsQueries = useQueries({
    queries: partIds.map((partId) => ({
      queryKey: ['part', partId],
      queryFn: fetchPart,
      enabled: isContinuedOrder,
    })),
  })

  // Aggregate the individual states:
  const isLoadingParts = partsQueries.some((query) => query.isLoading)
  const isFetchedParts = partsQueries.every((query) => query.isFetched)
  const latestPartsData = partsQueries.map((query) => query.data)

  /**
   * 4) Compare the local store's parts to the "fetched" parts once.
   *    If mismatch, force "selection".
   */
  const hasCheckedParts = useRef(false)
  useEffect(() => {
    if (!isContinuedOrder) return // normal flow
    if (!isFetchedParts) return
    if (!latestPartsData || hasCheckedParts.current) return
    hasCheckedParts.current = true

    const fetchedPartsMap = {}
    latestPartsData.forEach((p) => {
      fetchedPartsMap[p.id] = p
    })

    let mismatch = false

    for (const storedPart of storedWizardParts) {
      const freshPart = fetchedPartsMap[storedPart.id]
      if (!freshPart) {
        mismatch = true
        break
      }

      if (!comparePartAttributes(storedPart, freshPart)) {
        mismatch = true
        break
      }
    }

    if (!mismatch) {
      // If no mismatch, navigate to the stored `currentStepPath`.
      if (currentStepPath !== getLastPath(location.pathname)) {
        navigate(
          {
            pathname: `/marketplace/orderservice/orderparts/${currentStepPath}`,
            search: location.search,
          },
          { replace: true },
        )
      }
    } else {
      if (isContinuedOrder) {
        message.info(t('service.order_part.outdated_message'))
      }
      // If mismatch => Force "selection" / realizability
      setWizardData((prev) => ({
        ...prev,
        currentStepPath: 'selection',
        bookableParts: {
          ...prev.bookableParts,
          bookableParts: latestPartsData,
        },
      }))

      navigate(
        {
          pathname: `/marketplace/orderservice/orderparts/selection`,
          search: location.search,
        },
        { replace: true },
      )
    }
  }, [
    isContinuedOrder,
    isFetchedParts,
    latestPartsData,
    navigate,
    setWizardData,
    bookableParts,
    currentStepPath,
    location.pathname,
  ])

  const effectiveSelectedParts = useMemo(() => {
    // In continue mode (i.e. we have local storage wizard data)
    if (isContinuedOrder) {
      return storedWizardParts
    }
    // Otherwise use the "normal" selectedParts from context.
    return selectedParts
  }, [isContinuedOrder, bookableParts, selectedParts])

  // A small helper to get the last part of path
  const getLastPath = (path) => path.split('/').pop()

  /** 5) If user sets ?fresh=1, remove local storage. */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('fresh')) {
      localStorage.removeItem(ORDERPARTS_STORAGE_KEY)
      setWizardData(defaultWizardData)
    }
  }, [location.search, setWizardData])

  // Helper to update partial wizard data
  const updateWizardData = (partial) => {
    setWizardData((prev) => ({ ...prev, ...partial }))
  }

  const handleSetBookableParts = (newValue) => {
    setWizardData((prev) => {
      if (jsonEqual(prev.bookableParts, newValue)) {
        // They are effectively the same => skip the update
        return prev
      }
      return { ...prev, bookableParts: newValue }
    })
  }

  // 6) Step routing logic with integrated stepRoutes as objects.
  const stepRoutes = [
    {
      title: t('service.selection'),
      path: 'selection',
    },
    {
      title: t('service.order_part.step_config_title'),
      path: 'configuration',
    },
    {
      title: t('service.order'),
      path: 'ordersummary',
    },
  ]

  // Get the actual step path from the URL:
  const pathParts = location.pathname.split('/')
  const actualStepPath = pathParts[pathParts.length - 1]

  // Determine the current step index by matching the path
  const currentStepIndex = stepRoutes.findIndex(
    (route) => route.path === actualStepPath,
  )

  // Build the items for the antd Steps component
  const items = stepRoutes
    .filter((x) => x !== null)
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  // Mutations
  const addBookedOrders = useMutation(
    async (formData) => {
      const {
        amount,
        material_id,
        part,
        stl_file,
        maintain_alignment,
        extra_specifications,
        technical_drawing,
        selected_post_processing_methods,
      } = formData

      // First, update the part status and wait for it to complete
      let updatedPart
      try {
        updatedPart = await updateClientPartStatus.mutateAsync({
          partsToUpdate: part,
          serviceName: 'Request AM Offer',
        })
      } catch (err) {
        throw new Error(
          `${err.response?.status || ''} ${t(
            'service.order_part.error.update_client_part_status',
          )}`,
        )
      }

      updatedPart.data.db_id_client = part.db_id_client
      updatedPart.data.files = []

      let response
      try {
        response = await evoConsolidation.post(`${postOrder}`, {
          amount,
          material_id,
          part: updatedPart.data, // Use the updated part returned from the previous mutation
          partlist_name: activeGroupName || '',
          maintain_alignment: maintain_alignment,
          extra_specifications: extra_specifications,
          selected_post_processing_methods: selected_post_processing_methods,
        })
      } catch (err) {
        throw new Error(
          `${err.response?.status || ''} ${t(
            'service.order_part.error.evo_consolidation_post',
          )}`,
        )
      }

      try {
        await uploadFiles.mutateAsync({
          dbIdClient: updatedPart.data.db_id_client,
          orderPartId: response.data.id,
          stlFile: stl_file,
          technicalDrawingFile: technical_drawing,
        })
      } catch (err) {
        throw new Error(
          `${err.response?.status || ''} ${t(
            'service.order_part.error.upload_stl_file',
          )}`,
        )
      }

      return response.data
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(
          `${err.response?.status || ''} ${t(
            'service.order_part.error.generic_booking_error',
          )}`,
        )
      },
    },
  )

  const uploadFiles = useMutation(
    ({ dbIdClient, orderPartId, stlFile, technicalDrawingFile = null }) => {
      const formData = new FormData()
      formData.append(
        'stl_file',
        new File([stlFile], 'dateiname.stl', {
          type: 'application/vnd.ms-pki.stl',
        }),
      )

      if (technicalDrawingFile !== null) {
        formData.append(
          'technical_drawing_file',
          new File([technicalDrawingFile.data], technicalDrawingFile.filename, {
            type: technicalDrawingFile.type,
          }),
        )
      }

      return evoConsolidation
        .post(`${postPartsConsolidation}${dbIdClient}/files`, formData)
        .then((res) => {
          const fileLocations = res.data
          putFileLocationsInOrderParts.mutate({
            orderPartId: orderPartId,
            stlFileLocation: fileLocations.stl_file,
            technicalDrawingFileLocation: fileLocations?.technical_drawing_file,
          })
        })
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not upload files`)
      },
    },
  )

  const updateClientPartStatus = useMutation(putStatusAttributes, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allgroups'])
      queryClient.invalidateQueries(['notifications'])
      setLoading(false)
    },
    onError: (err) => {
      message.error(`${err.response.status} Could not update status`)
    },
  })

  const createOrderPartsList = useMutation(
    (body) => {
      return evoConsolidation.post(`${postOrderList}`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        setLoading(false)
      },
      onError: (err) => {
        message.error(`${err.response.status} Could not create order`)
      },
    },
  )

  const putFileLocationsInOrderParts = useMutation(
    ({ orderPartId, stlFileLocation, technicalDrawingFileLocation = null }) => {
      let endpoint = `${postOrder}${orderPartId}/files?file_path=${stlFileLocation}`
      if (technicalDrawingFileLocation !== null) {
        endpoint = `${endpoint}&technical_drawing_file_path=${technicalDrawingFileLocation}`
      }

      return evoConsolidation.put(endpoint)
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not put file locations`)
      },
    },
  )

  // Next / Back step logic
  const goNext = () => {
    if (currentStepIndex < stepRoutes.length - 1) {
      setConfigState(false)
      const nextStep = stepRoutes[currentStepIndex + 1]
      updateWizardData({ currentStepPath: nextStep.path })
      navigate(`/marketplace/orderservice/orderparts/${nextStep.path}`)
    }
  }

  const goPrev = () => {
    if (currentStepIndex > 0) {
      const prevStep = stepRoutes[currentStepIndex - 1]
      updateWizardData({ currentStepPath: prevStep.path })
      navigate(`/marketplace/orderservice/orderparts/${prevStep.path}`)
    }
  }

  // Final submit
  const handleOk = async () => {
    setCurrent(0)
    setSelectedParts([])
    setOrderToRepeat(null)
    setButtonActive(true)

    setLoading(true)
    setVisible(false)

    try {
      const results = await Promise.all(
        accumulatedFormData.map((formData) =>
          addBookedOrders.mutateAsync(formData),
        ),
      )

      // Remove local store progress
      localStorage.removeItem(ORDERPARTS_STORAGE_KEY)
      setWizardData(defaultWizardData)

      // Build request for OrderPartsList
      const orderPartIDs = results.map((data) => data.id)
      orderPartsListData.list_of_orderparts = orderPartIDs

      // match newly created OrderParts to part quantities
      const exchangePartIdsForOrderPartIds = (obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([partId, orderPartQuantity]) => [
            results.find((o) => o.part.db_id_client === partId).id,
            orderPartQuantity,
          ]),
        )

      const requestBody = {
        ...orderPartsListData,
        main_order: {
          ...orderPartsListData.main_order,
          orderpart_quantity: exchangePartIdsForOrderPartIds(
            orderPartsListData.main_order.orderpart_quantity,
          ),
        },
        list_of_suborders: orderPartsListData.list_of_suborders?.map((sub) => ({
          ...sub,
          orderpart_quantity: exchangePartIdsForOrderPartIds(
            sub.orderpart_quantity,
          ),
        })),
      }

      createOrderPartsList.mutate(requestBody)
    } catch (error) {
      console.error('Error on one or more booked Orders:', error)
      message.error('Could not create order!')
      setLoading(false)
    }
  }

  // Control “Next” button availability
  useEffect(() => {
    // controls the "next" button
    setConfigState(!(bookableParts && bookableParts.bookableParts.length === 0))
  }, [bookableParts])

  // Just as in your original code
  const fcData = {
    not_feasible_parts: [],
    feasible_parts: [],
  }

  // Render step content based on the current path
  let stepContent
  switch (actualStepPath) {
    case 'selection':
      stepContent = (
        <StepOneSelection
          fcData={fcData}
          selectedParts={effectiveSelectedParts}
          setBookableParts={handleSetBookableParts}
          bookableParts={bookableParts}
          servicesData={servicesData}
          bookedServiceStatus={bookedServiceStatus}
        />
      )
      break

    case 'configuration':
      stepContent = (
        <StepTwoConfigOrderPart
          fcData={fcData}
          selectedParts={effectiveSelectedParts}
          bookableParts={bookableParts}
          setAccumulatedFormData={(val) =>
            setWizardData((prev) => ({ ...prev, accumulatedFormData: val }))
          }
          setOrderPartsListData={(val) =>
            setWizardData((prev) => ({ ...prev, orderPartsListData: val }))
          }
          setConfigFormData={(val) =>
            setWizardData((prev) => ({ ...prev, configFormData: val }))
          }
          wizardData={wizardData}
          setConfigState={setConfigState}
          configState={configState}
          orderToRepeat={orderToRepeat}
          initialLocalState={orderPartsListData}
        />
      )
      break

    case 'ordersummary':
      stepContent = (
        <StepThreeSummaryOrderPart
          fcData={fcData}
          selectedParts={effectiveSelectedParts}
          accumulatedFormData={accumulatedFormData}
          orderPartsListData={orderPartsListData}
          bookableParts={bookableParts}
          setButtonActive={setButtonActive}
          bookedServiceStatus={bookedServiceStatus}
        />
      )
      break

    default:
      stepContent = <div>Unknown step</div>
  }

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(bookedServiceStatus[1]?.servicename || 'Order Service')}
          </h1>

          <div className="contentcard">
            <StyledSteps
              current={currentStepIndex}
              items={items}
              type="navigation"
            />
            {isContinuedOrder && isLoadingParts ? (
              <CenteredSpinner />
            ) : (
              <div>{stepContent}</div>
            )}

            <StyledStepButtons>
              {currentStepIndex < stepRoutes.length - 1 ? (
                <Popconfirm
                  disabled={configState}
                  title="Some changes have to be made"
                  description="Some parts need configuration before proceeding."
                >
                  <Button
                    type="primary"
                    onClick={goNext}
                    disabled={
                      !configState || (isContinuedOrder && isLoadingParts)
                    }
                  >
                    {t('button.next')}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  type="primary"
                  onClick={handleOk}
                  disabled={!buttonActive}
                  loading={loading}
                >
                  {t('service.order_part.order')}
                </Button>
              )}

              {currentStepIndex > 0 && (
                <Button onClick={goPrev}>{t('button.back')}</Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}

      {!visible && <StatusPage loading={loading} setCurrent={setCurrent} />}
    </>
  )
}

export default Orderparts
