import React, { useState, useEffect } from 'react'
import { Button, Select, Input, Row, Col, Switch, theme } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { StyledItem } from './styledComponents'
import { useTranslation } from 'react-i18next'
import TechnicalDrawingDownload from '$components/FileDownload/TechnicalDrawingDownload'

const { TextArea } = Input
const { useToken } = theme

const DESCRIBABLE_METHODS = ['custom', 'painted', 'color_dyed']

const ConfigOrderPartDetailView = ({ record, materials, wizardData }) => {
  const { t, i18n } = useTranslation()
  const { token } = useToken()

  const materialName = record.attributes.find(
    (attribute) => attribute.key === 'Material',
  ).value
  const postProcessingMethods = materials.find(
    (material) => material.name === materialName,
  ).post_processing_methods

  const [options, setOptions] = useState(
    postProcessingMethods.map((method) => {
      return {
        value: method,
        label: t(`service.order_part.follow_up_treatments.${method}`),
        disabled: false,
        displayDescriptionField: DESCRIBABLE_METHODS.includes(method),
      }
    }),
  )

  // Initialize with no fields by default
  const [fields, setFields] = useState([])
  const [fieldValues, setFieldValues] = useState({})

  useEffect(() => {
    setOptions((prev) =>
      prev.map((option) => ({
        ...option,
        label: t(
          `service.order_part.follow_up_treatments.${option.value}`,
          option.value,
        ),
      })),
    )
  }, [i18n.language])

  const handleChange = (value, fieldId) => {
    const prevValue = fieldValues?.[fieldId]
    setFieldValues((prev) => ({ ...prev, [fieldId]: value }))

    setOptions((prev) =>
      prev.map((option) => {
        if (option.value === prevValue) {
          return { ...option, disabled: false }
        }
        if (option.value === value) {
          return { ...option, disabled: true }
        }
        return option
      }),
    )
  }

  const handleAdd = () => {
    if (fields.length < options.length) {
      const newFieldId = fields.length > 0 ? Math.max(...fields) + 1 : 0
      setFields([...fields, newFieldId])
    }
  }

  const handleRemove = (fieldId) => {
    const removedValue = fieldValues[fieldId]
    setFields(fields.filter((f) => f !== fieldId))
    setFieldValues((prev) => {
      const { [fieldId]: _removed, ...rest } = prev
      return rest
    })
    setOptions((prev) =>
      prev.map((option) =>
        option.value === removedValue ? { ...option, disabled: false } : option,
      ),
    )
  }

  useEffect(() => {
    if (
      wizardData.configFormData &&
      wizardData.configFormData.selected_post_processing_methods &&
      wizardData.configFormData.selected_post_processing_methods[record.id]
    ) {
      const methods =
        wizardData.configFormData.selected_post_processing_methods[record.id]
      // Create fields using the index as unique IDs
      const initialFields = methods.map((_, index) => index)
      setFields(initialFields)

      // Create fieldValues mapping each field ID to the selected post_processing_method
      const initialFieldValues = {}
      methods.forEach((method, index) => {
        initialFieldValues[index] = method.post_processing_method
      })
      setFieldValues(initialFieldValues)

      // Update the options so that selected ones are disabled
      setOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          disabled: methods.some(
            (method) => method.post_processing_method === option.value,
          ),
        })),
      )
    }
  }, [wizardData, record.id])

  return (
    <div style={{ background: 'inherit' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '32%',
        }}
      >
        <h4>{t('service.order_part.follow_up_treatments.title')}</h4>
        <Button
          type="primary"
          size="small"
          icon={<PlusOutlined />}
          onClick={handleAdd}
          disabled={fields.length >= options.length}
        >
          {t('service.order_part.add')}
        </Button>
      </div>

      {fields.length === 0 ? (
        <p style={{ color: token.colorTextDisabled }}>
          {t('service.order_part.follow_up_treatments.no_follow_up_treatment')}
        </p>
      ) : (
        fields.map((field, index) => {
          const selectedValue = fieldValues[field]
          const selectedOption = options.find(
            (option) => option.value === selectedValue,
          )

          return (
            <Row key={field}>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={8}>
                    <StyledItem
                      layout="vertical"
                      label={`${t('service.order_part.follow_up_treatments.label_select')} ${index + 1}`}
                      name={[
                        'selected_post_processing_methods',
                        record.id,
                        index,
                        'post_processing_method',
                      ]}
                    >
                      <Select
                        size="small"
                        onChange={(value) => handleChange(value, field)}
                        options={options}
                      />
                    </StyledItem>
                  </Col>

                  {selectedOption && selectedOption.displayDescriptionField && (
                    <Col span={8}>
                      <StyledItem
                        layout="vertical"
                        label={t(
                          `service.order_part.follow_up_treatments.description.${selectedOption.value}.label`,
                        )}
                        name={[
                          'selected_post_processing_methods',
                          record.id,
                          index,
                          'description',
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={t(
                            `service.order_part.follow_up_treatments.description.${selectedOption.value}.placeholder`,
                          )}
                        />
                      </StyledItem>
                    </Col>
                  )}
                  <div
                    key={field}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      marginBottom: '10px',
                    }}
                  >
                    <Button
                      shape="circle"
                      icon={<MinusOutlined />}
                      size="small"
                      onClick={() => handleRemove(field)}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          )
        })
      )}

      <h4 style={{ paddingTop: '20px' }}>
        {t('service.order_part.extra_specifications')}
      </h4>
      <StyledItem
        labelAlign="left"
        colon={false}
        name={['config_order_part', record.id, 'maintain_alignment']}
        label={t('service.order_part.maintain_as_in_3d_file')}
        valuePropName="checked"
      >
        <Switch size="small" style={{ marginBottom: '2px' }} />
      </StyledItem>
      <StyledItem
        layout="vertical"
        label={`${t('service.order_part.extra_specifications')} :`}
        name={['config_order_part', record.id, 'extra_specifications']}
      >
        <TextArea
          showCount
          rows={4}
          maxLength={1000}
          placeholder={t('service.order_part.extra_specifications_placeholder')}
        />
      </StyledItem>
      <TechnicalDrawingDownload record={record} />
    </div>
  )
}

export default ConfigOrderPartDetailView
