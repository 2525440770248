import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { StyledModal } from '../styledComponents'
import { Button, message, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import Spinner from '$components/Spinner'
import * as XLSX from 'xlsx'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { postMultipleParts, putPartsToGroup } from '$api/client'
import { useRefetchTimer } from '$context/RefetchTimerProvider'
import INTERNAL_PROPERTIES from './internalProperties'
import MappingForm from './MappingForm'
import { isDemoError } from '$utils/demo'

const UploadExcelButton = ({ groups, activeGroup }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [excelObject, setExcelObject] = useState(undefined)
  const [items, setItems] = useState([])

  const queryClient = useQueryClient()
  const { startRefetch, stopRefetch } = useRefetchTimer()
  const addPartsMutation = useMutation({
    mutationFn: postMultipleParts,
    onSuccess: (response, variables) => {
      stopRefetch()

      if (variables[0].group) {
        addToGroupMutation.mutate({
          partsIds: response.data,
          groupId: variables[0].group,
        })
      } else {
        setLoading(false)
        queryClient.refetchQueries(['allpartspaginated'])
        queryClient.invalidateQueries(['parts'])
      }
    },
    onError: (err) => {
      setLoading(false)
      console.error(err)
      stopRefetch()
      if (!isDemoError(err)) {
        message.error(`there was an error!`)
      }
    },
  })
  const addToGroupMutation = useMutation({
    mutationFn: putPartsToGroup,
    onSuccess: () => {
      setLoading(false)
      queryClient.refetchQueries(['allpartspaginated'])
      queryClient.invalidateQueries(['parts'])
    },
    onError: (err) => {
      setLoading(false)
      console.error(err)
      if (!isDemoError(err)) {
        message.error(`there was an error!`)
      }
    },
  })

  const getPartsFromExcel = (mapping) =>
    excelObject.map((row) => {
      // part definition
      const partToAdd = {
        id_part_client: row[mapping.id_part_client],
        name: row[mapping.name],
        attributes: [],
        float_attributes: [],
        status_attributes: [],
      }

      // add float attributes
      for (const internalProperty of INTERNAL_PROPERTIES.filter(
        (val) => val.float_attribute,
      )) {
        const value = row[mapping[internalProperty.name]]
        if (typeof value === 'number') {
          partToAdd.float_attributes.push({
            key: internalProperty.name,
            value: value * (mapping?.[`${internalProperty.name}-unit`] || 1),
          })
        }
      }

      // add other attributes
      for (const internalProperty of INTERNAL_PROPERTIES.filter(
        (val) => val.attribute,
      )) {
        const value = row[mapping[internalProperty.name]]
        if (value) {
          partToAdd.attributes.push({
            key: internalProperty.name,
            value: value,
          })
        }
      }

      return partToAdd
    })

  const handleOk = (mappingFormValues) => {
    setIsModalOpen(false)

    // set loading now to include transform of excel data to parts in loading time
    setLoading(true)

    if (typeof mappingFormValues.group === 'object') {
      mappingFormValues.group = mappingFormValues.group.value
    }

    const partsToAdd = getPartsFromExcel(mappingFormValues)

    // Map over partsData to add or update the group in each object
    const partsWithGroup = partsToAdd.map((part) => ({
      ...part,
      group: mappingFormValues.group,
    }))

    addPartsMutation.mutate(partsWithGroup, {
      onSuccess: () => {
        message.success(
          t('parts_master_data.upload.success_message', {
            count: partsToAdd.length,
          }),
        )
      },
    })

    // refetch data periodically while uploading to provide visual feedback when uploading lots of parts
    startRefetch(
      500,
      () => {
        queryClient.refetchQueries(['allpartspaginated'])
        queryClient.refetchQueries(['parts'])
      },
      0,
    )
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const readExcel = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { cellDates: true })
        const wsname = wb.SheetNames[0]
        let ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        setExcelObject(data)
        const rawSheetData = XLSX.utils.sheet_to_json(ws, { header: 1 })
        resolve(rawSheetData[0])
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleUpload = async (file) => {
    const items = await readExcel(file)
    setIsModalOpen(true)
    setItems(items)
  }

  if (loading) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          verticalAlign: 'middle',
          padding: '25% 0',
        }}
      >
        <Spinner />
        {t('parts_master_data.upload.upload_in_progress')}
      </div>
    )
  }

  return (
    <>
      <Upload
        name="file"
        accept=".xlsx"
        maxCount="1"
        beforeUpload={(info) => {
          handleUpload(info)

          // always return false to disable default upload as we are handling that differently
          return false
        }}
      >
        <Button
          icon={<UploadOutlined />}
          type="primary"
          size="large"
          style={{ borderRadius: '10px 0 0 10px' }}
        >
          {t('parts_master_data.upload.upload_button')}
        </Button>
      </Upload>
      <StyledModal
        destroyOnClose="true"
        centered="true"
        title={t('parts_master_data.upload.mapping_modal_title')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            form="mapDataForm"
            key="submit"
            htmlType="submit"
          >
            {t('button.submit')}
          </Button>,
        ]}
      >
        <MappingForm
          name="mapDataForm"
          internalProperties={INTERNAL_PROPERTIES}
          propertiesToMap={items}
          handleOk={handleOk}
          data={excelObject}
          groups={groups}
          activeGroup={activeGroup}
        />
      </StyledModal>
    </>
  )
}

export default UploadExcelButton
