import { useState } from 'react'
import { Tag, Modal, Input, theme } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Tooltip from '$components/Tooltip'

const { TextArea } = Input
const { useToken } = theme

const RequestRejectedCell = ({ t, reason }) => {
  const { token } = useToken()
  const [open, setOpen] = useState(false)

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpen(!open)
  }

  const hasReason = reason?.trim().length > 0
  return (
    <div>
      <Tooltip
        title={t('service.order_part.tooltip.request_rejected')}
        style={{ cursor: 'pointer' }}
        onClick={toggleModal}
      >
        <Tag color="red">
          {t('service.order_part.status.request_rejected')}
          <InfoCircleOutlined style={{ fontSize: 12, marginLeft: 5 }} />
        </Tag>
      </Tooltip>
      <Modal
        open={open}
        onCancel={toggleModal}
        footer={null}
        title={t('service.order_part.rejected_modal.title')}
      >
        {hasReason ? (
          <TextArea
            variant="borderless"
            value={reason}
            readOnly
            autoSize={{ minRows: 3, maxRows: 10 }}
            style={{
              resize: 'none',
              height: 200,
              overflowY: 'auto',
            }}
          />
        ) : (
          <p style={{ color: token.colorTextDisabled }}>
            <strong>
              {t('service.order_part.rejected_modal.no_rejection_reason')}
            </strong>
          </p>
        )}
      </Modal>
    </div>
  )
}

export default RequestRejectedCell
