import Color from 'color'
import Tooltip from '$components/Tooltip'
import { StyledTag } from './styledComponents'

const Label = ({
  name,
  description,
  color: colorString = 'ccc',
  onRemove,
  onClick,
  annotated = false,
  annotation,
}) => {
  if (!name) {
    return <></>
  }

  const hex = `#${colorString}`
  const color = Color(hex)

  let tooltipText = description
  if (annotated && annotation) {
    tooltipText += ': ' + annotation
  }

  return (
    <Tooltip title={tooltipText}>
      <StyledTag
        className={color.isLight() ? 'light' : 'dark'}
        color={color.hex()}
        bordered={true}
        closable={!!onRemove}
        onClose={(e) => {
          onRemove(e, { name, description, color })
        }}
        onClick={() => onClick && onClick({ name, description, color })}
        style={{
          cursor: onClick ? 'pointer' : 'default',
          borderColor: color.darken(0.2).hex(),
        }}
      >
        {name}
      </StyledTag>
    </Tooltip>
  )
}

export default Label
