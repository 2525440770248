import { localeParseFloat, numberRenderer } from '$utils/i18n'
import { InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { centToEuro, euroToCent } from '$components/OrderPartsListTable/utils'

const CustomInputNumber = ({ mode, ...props }) => {
  const { i18n, t } = useTranslation()

  return (
    <InputNumber
      formatter={(value) => {
        if (value == null || value === '') {
          return ''
        }
        switch (mode) {
          case 'currency':
            return numberRenderer(t)(centToEuro(value))
          default:
            return numberRenderer(t)(value)
        }
      }}
      parser={(value) => {
        if (value === '') return undefined
        switch (mode) {
          case 'currency':
            return euroToCent(localeParseFloat(value, i18n))
          default:
            return localeParseFloat(value, i18n)
        }
      }}
      {...props}
    />
  )
}

export default CustomInputNumber
