import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Upload, Typography, Space, message } from 'antd'
import { useQuery } from '@tanstack/react-query'
import {
  getTechnicalDrawing,
  useDeleteTechnicalDrawing,
  usePostTechnicalDrawing,
} from '$api/client'
import Tooltip from '$components/Tooltip'
import { checkFileSize } from '$utils'

const { Title } = Typography

const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/pdf',
]

const TechnicalDrawingUpload = ({ record }) => {
  const { t } = useTranslation()
  const queryKeyParts = 'parts'
  const queryKeyTechnicalDrawing = 'technicalDrawing'
  const [fileList, setFileList] = useState([])

  const beforeUpload = (file) => {
    if (!checkFileSize(file.size, '20 MB', t)) return Upload.LIST_IGNORE

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      message.error(t('part_technical_drawing.file_type_not_allowed'))
      return Upload.LIST_IGNORE
    }
    return true
  }

  const { data: technicalDrawing } = useQuery({
    queryKey: [queryKeyTechnicalDrawing, record.id],
    queryFn: ({ queryKey: [, id] }) => getTechnicalDrawing(id),
    enabled: !!record.technical_drawing,
  })

  const { mutate: deleteTechnicalDrawingMutation } = useDeleteTechnicalDrawing(
    queryKeyParts,
    queryKeyTechnicalDrawing,
  )

  const { mutate: postTechnicalDrawingMutation } = usePostTechnicalDrawing(
    queryKeyParts,
    queryKeyTechnicalDrawing,
  )

  const customUpload = ({ file, onProgress }) => {
    postTechnicalDrawingMutation(
      { partId: record.id, file, onProgress },
      {
        onError: () => {
          setFileList([])
        },
      },
    )
  }

  const onRemove = () => {
    deleteTechnicalDrawingMutation(record.id, {
      onSuccess: () => {
        setFileList([])
      },
    })
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handlePreview = (file) => {
    const url = file.url
    const a = document.createElement('a')
    a.href = url
    a.download = file.name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  useEffect(() => {
    if (technicalDrawing) {
      setFileList((prevList) => [
        {
          uid: prevList.length > 0 ? prevList[0].uid : '1',
          name: technicalDrawing.filename,
          status: 'done',
          url: URL.createObjectURL(
            technicalDrawing.data,
            technicalDrawing.type,
          ),
        },
      ])
    }
  }, [technicalDrawing])

  return (
    <>
      <Space size="small">
        <Title style={{ fontSize: '12px' }}>
          {t('part_technical_drawing.title')}
        </Title>
        <Tooltip title={t('part_technical_drawing.tooltip')}>
          <InfoCircleOutlined style={{ fontSize: '14px', marginTop: '5px' }} />
        </Tooltip>
      </Space>
      <Upload
        accept=".png,.pdf,.jpeg,.jpg,.tiff,.tif"
        fileList={fileList}
        customRequest={customUpload}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        showUploadList={{ showRemoveIcon: true }}
        onPreview={handlePreview}
      >
        {fileList.length === 0 && (
          <Button icon={<UploadOutlined />}>
            {t('part_technical_drawing.upload_button')}
          </Button>
        )}
      </Upload>
    </>
  )
}
export default TechnicalDrawingUpload
