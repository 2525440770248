import { updateStatusAttributesAfterOrder } from '$api/client'
import {
  fetchAllOrderPartsLists,
  setClientAcceptedOffer,
  updateOrderPartProviderOffers,
} from '$api/evoAPIs'
import Form from '$components/Form'
import { CenteredSpinner } from '$components/Spinner'
import { isDemoError } from '$utils/demo'
import useMaterialMapping from '$utils/useMaterialMapping'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { message, Segmented } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ExpandedRow from './ExpandedRow'
import { getColumns } from './columns'
import { StyledTable } from './ExpandedRow/styledComponents'
import { useTheme } from 'styled-components'
import {
  OFFER_ACCEPTED,
  OFFER_MADE,
  REQUEST_RECEIVED,
  REQUEST_REJECTED,
} from '$components/OrderPartsListStatus'
import { useUser } from '$context/user'

const OrderPartsListTable = () => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const theme = useTheme()
  const { isServiceProvider } = useUser()

  // State variables for pagination, sorting, and filtering
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [sorting, setSorting] = useState({
    field: 'time_stamp',
    order: -1,
  })
  const [filters, setFilters] = useState({
    status: null,
    order_number: null,
  })

  // Store copies of the OrderPart Forms data to calculate summary data in table
  const [orderPartFormData, setOrderPartFormData] = useState({})
  const [orderPartListStatus, setOrderPartListStatus] = useState({})

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const queryFilters = {
    pageNr: pagination.current,
    pageSize: pagination.pageSize,
    sorting,
    filters,
  }

  const {
    data: { items: orderPartListsData } = {},
    isLoading: orderPartsListsAreLoading,
  } = useQuery(
    ['allorderedpartslists', queryFilters],
    fetchAllOrderPartsLists,
    {
      keepPreviousData: true,
      onSuccess: ({ items, page, total }) => {
        setOrderPartFormData(orderPartListToFormData(items))
        setPagination((prev) => ({
          ...prev,
          current: page,
          total,
        }))
      },
    },
  )

  const updateProviderOffer = useMutation({
    mutationFn: updateOrderPartProviderOffers,
    onSuccess: (_resp, { order_part_list_id, order_part_id }) => {
      setOrderPartListStatus({
        ...orderPartListStatus,
        [order_part_list_id]: [
          ...(orderPartListStatus[order_part_list_id] ?? []),
          order_part_id,
        ],
      })
    },
  })

  const clientAcceptedOffer = useMutation({
    mutationFn: setClientAcceptedOffer,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })

      for (let part of data.list_of_orderparts) {
        updateClientPartStatus.mutate(part)
      }
    },
    onError: (error) => {
      if (isDemoError(error)) {
        queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
      }
    },
  })

  const updateClientPartStatus = useMutation(updateStatusAttributesAfterOrder, {
    onError: (err) => {
      message.error(`${err.response.status} Could not update status`)
    },
  })

  const orderPartListToFormData = (orderPartList) => {
    // Transform object to formData object
    // Turn all arrays into objects with ids as key so we can map form items to object

    return Object.fromEntries(
      orderPartList.map((orderPartList) => [
        orderPartList.id,
        Object.fromEntries(
          orderPartList.list_of_orderparts.map((orderPart) => [
            orderPart.id,
            {
              ...orderPart,
              provider_offers: Object.fromEntries(
                orderPart.provider_offers.map((offer) => [
                  offer.offer_id,
                  offer,
                ]),
              ),
            },
          ]),
        ),
      ]),
    )
  }

  const resetOrderPartFormData = (orderPartListId, orderPartId) => {
    setOrderPartFormData({
      ...orderPartFormData,
      [orderPartListId]: {
        ...orderPartFormData[orderPartListId],
        [orderPartId]: orderPartListsData.data
          .find((orderPartList) => orderPartList.id === orderPartListId)
          .list_of_orderparts.find((orderPart) => orderPart.id === orderPartId),
      },
    })
    queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
  }

  const handleFormChange = (formName, { forms }) => {
    const [orderPartListId, orderPartId] = formName
    setOrderPartFormData({
      ...orderPartFormData,
      [orderPartListId]: {
        ...orderPartFormData[orderPartListId],
        [orderPartId]:
          forms[`${orderPartListId},${orderPartId}`].getFieldValue(),
      },
    })
  }

  const handleFormFinish = async (formName, { values }) => {
    const [order_part_list_id, order_part_id] = formName

    updateProviderOffer.mutate(
      {
        order_part_list_id,
        order_part_id,
        provider_offers: Object.entries(values.provider_offers).map(
          ([offer_id, offer]) => ({ ...offer, offer_id }),
        ),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
        },
        onError: (error) => {
          messageApi.error('Saving data failed!')
          console.error(error)
        },
      },
    )
  }

  const handleAcceptOffer = (order_parts_list_id) => {
    clientAcceptedOffer.mutate(
      {
        order_parts_list_id,
        client_accepted_offer: true,
      },
      {
        onSuccess: () => {
          message.success('Offer accepted successfully.')
        },
        onError: (error) => {
          if (!isDemoError(error)) {
            message.error('Accepting offer failed!')
          }
        },
      },
    )
  }

  const openOrderService = (record) => {
    navigate('/marketplace/orderservice/orderparts/selection', {
      state: { orderToRepeat: JSON.stringify(record) },
    })
  }

  // Handle table changes for pagination, sorting, and filtering
  const handleTableChange = (pagination, tableFilters, sorting) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    })

    setSorting({
      field: sorting.field,
      order: sorting.order,
    })

    setFilters((prevFilters) => {
      let orderNumberValue = null
      if (tableFilters.order_number && tableFilters.order_number.length > 0) {
        const { searchTerm } = tableFilters.order_number[0]
        orderNumberValue = searchTerm
      }
      return {
        ...prevFilters,
        order_number: orderNumberValue,
      }
    })
  }

  const filterOptions = [
    {
      label: t('All'),
      value: null,
    },
    {
      label: t('service.order_part.status.request_received'),
      value: REQUEST_RECEIVED.description,
    },
    {
      label: t('service.order_part.status.request_rejected'),
      value: REQUEST_REJECTED.description,
    },
    {
      label: t('service.order_part.status.offer_made'),
      value: OFFER_MADE.description,
    },
    {
      label: t('service.order_part.status.offer_accepted'),
      value: OFFER_ACCEPTED.description,
    },
  ]

  let columns = getColumns({
    t,
    filters,
    theme,
    materialsById,
    handleAcceptOffer,
    openOrderService,
    orderPartFormData,
    orderPartListsData,
    orderPartListStatus,
  })

  if (orderPartsListsAreLoading || materialsAreLoading) {
    return <CenteredSpinner />
  }

  return (
    <>
      {contextHolder}
      <Form.Provider
        onFormChange={handleFormChange}
        onFormFinish={handleFormFinish}
      >
        {isServiceProvider && (
          <Segmented
            options={filterOptions}
            value={filters.status}
            onChange={(value) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                status: value,
              }))
            }}
            style={{ marginBottom: '15px' }}
          />
        )}
        <StyledTable
          columns={columns}
          dataSource={orderPartListsData}
          rowKey={(record) => record.id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
          expandable={{
            showExpandColumn: true,
            expandRowByClick: true,
            expandedRowRender: (record) => (
              <ExpandedRow
                orderPartsList={record}
                orderPartListStatus={orderPartListStatus}
                orderPartFormData={orderPartFormData[record.id]}
                resetOrderPartFormData={(orderPartId) =>
                  resetOrderPartFormData(record.id, orderPartId)
                }
                editable={!record.client_accepted_offer}
                showProviderOffers={record.provider_accepted_offer}
                showRejectedProviderOffers={false}
                showOfferTerms={record.provider_accepted_offer}
              />
            ),
          }}
        />
      </Form.Provider>
    </>
  )
}

export default OrderPartsListTable
