import { useState, useEffect, useMemo, useRef } from 'react'
import { StyledLayout } from '$globalStyledComponents/Layout'
import Footer from '$components/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FcUpdateParts } from '$utils/FcUpdateParts'
import { fetchPart } from '$api/client' // individual fetch function
import { useQueries } from '@tanstack/react-query'
import PartsTable from './PartsTable'
import { ORDERPARTS_STORAGE_KEY } from '$constants'
import ServiceButtons from './ServiceButtons'
import { SERVICES } from '$constants'

const { Content } = StyledLayout

const Marketplace = (props) => {
  const { t } = useTranslation()
  const location = useLocation()

  const [selectedParts, setSelectedParts] = useState({})
  const [bookedServiceStatus, setBookedServiceStatus] = useState([false, ''])
  const [activeGroupName, setActiveGroupName] = useState('')
  const [orderToRepeat, setOrderToRepeat] = useState(null)
  const updatedRef = useRef(false)

  useEffect(() => {
    if (location.pathname === '/marketplace') {
      setBookedServiceStatus([false, ''])
    }

    if (location?.state?.orderToRepeat) {
      try {
        const parsed = JSON.parse(location.state.orderToRepeat)
        setOrderToRepeat(parsed)
      } catch (err) {
        console.error('Invalid `orderToRepeat` JSON:', err)
      }
    }
  }, [location])

  // Reset our ref when orderToRepeat changes
  useEffect(() => {
    updatedRef.current = false
  }, [orderToRepeat])

  const partIds = useMemo(() => {
    if (!orderToRepeat?.list_of_orderparts) return []
    return orderToRepeat.list_of_orderparts.map((op) => op.part.db_id_client)
  }, [orderToRepeat])

  // Fire off individual queries for each part
  const partsQueries = useQueries({
    queries: partIds.map((partId) => ({
      queryKey: ['part', partId],
      queryFn: fetchPart,
      enabled: !!orderToRepeat,
    })),
  })

  // Compute aggregate states
  const isLoadingParts = partsQueries.some((query) => query.isLoading)
  const allFetched =
    partsQueries.length > 0 && partsQueries.every((query) => query.isFetched)

  // Mimic the onSuccess from your previous code; run only once per orderToRepeat
  useEffect(() => {
    if (orderToRepeat && allFetched && !isLoadingParts && !updatedRef.current) {
      const updatedParts = {}
      partsQueries.forEach((query) => {
        if (query.data) {
          updatedParts[query.data.id] = query.data
        }
      })

      setSelectedParts((prev) => {
        const mergedParts = { ...prev, ...updatedParts }
        if (orderToRepeat) {
          const newStoragePayload = {
            bookableParts: {
              bookableParts: Object.values(mergedParts),
              notBookableParts: [],
            },
            accumulatedFormData: [],
            orderPartsListData: orderToRepeat,
            currentStepPath: 'selection',
          }
          localStorage.setItem(
            ORDERPARTS_STORAGE_KEY,
            JSON.stringify(newStoragePayload),
          )

          const orderpartService = SERVICES.find(
            (service) => service.id === '63dcfb10f81b69c6d3a7c42f',
          )
          setBookedServiceStatus([true, orderpartService])
        }
        return mergedParts
      })

      updatedRef.current = true
    }
  }, [orderToRepeat, allFetched, isLoadingParts, partsQueries])

  return (
    <StyledLayout className="layout">
      {!bookedServiceStatus[0] ? (
        <>
          <Content>
            <h1 style={{ margin: '50px 0' }}>{t('Marketplace')}</h1>
            <ServiceButtons
              selectedParts={selectedParts}
              setBookedServiceStatus={setBookedServiceStatus}
            />
            <FcUpdateParts />
            <PartsTable
              selectedParts={selectedParts}
              setSelectedParts={setSelectedParts}
              setGroup={(group) => {
                if (group) {
                  setActiveGroupName(group.list_name)
                } else {
                  setActiveGroupName('')
                }
              }}
            />
          </Content>
        </>
      ) : (
        <Content>
          <Outlet
            context={{
              bookedServiceStatus,
              selectedParts: Object.values(selectedParts),
              setSelectedParts,
              setCurrent: props.setCurrent,
              activeGroupName,
              orderToRepeat,
              setOrderToRepeat,
            }}
            bookedServiceStatus={bookedServiceStatus[1]}
            selectedParts={Object.values(selectedParts)}
            setSelectedParts={setSelectedParts}
            setCurrent={props.setCurrent}
            activeGroupName={activeGroupName}
            orderToRepeat={orderToRepeat}
            setOrderToRepeat={setOrderToRepeat}
          />
        </Content>
      )}
      <Footer />
    </StyledLayout>
  )
}

export default Marketplace
