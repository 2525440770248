import { theme } from 'antd'
import { UilPaperclip } from '@iconscout/react-unicons'
import { useTranslation } from 'react-i18next'
import { useUser } from '$context/user'
import { evoConsolidation } from '$api'
import { consolidationPart } from '$api/evoAPIs'

const { useToken } = theme

const OrderTechnicalDrawingDownload = ({ orderPart }) => {
  const { token } = useToken()
  const { t } = useTranslation()
  const { isServiceProvider } = useUser()

  const downloadTechnicalDrawing = (filename) => {
    evoConsolidation
      .get(
        isServiceProvider
          ? `${consolidationPart}files/?file_name=${filename}`
          : `${consolidationPart}${orderPart.part.db_id_client}/file?file_name=${filename}`,
        {
          responseType: 'blob',
        },
      )
      .then(async (res) => {
        const asyncBlob = await res.data.arrayBuffer()
        const blob = new Blob([asyncBlob], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <p>{t('received_requests.technical_drawing')}:</p>

      {orderPart?.user_technical_drawing_file_location ? (
        <p>
          <UilPaperclip
            size="16"
            color={token.colorText}
            style={{ marginBottom: '-4px' }}
          />
          <a
            onClick={() =>
              downloadTechnicalDrawing(
                orderPart?.user_technical_drawing_file_location,
              )
            }
          >
            {orderPart.user_technical_drawing_file_location}
          </a>
        </p>
      ) : (
        <p style={{ color: token.colorTextDisabled }}>
          {t('received_requests.technical_drawing_not_available')}
        </p>
      )}
    </>
  )
}

export default OrderTechnicalDrawingDownload
