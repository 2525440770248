export default class Company {
  constructor({
    CompanyChain: companyChain,
    address_line_1,
    address_line_2,
    postalcode,
    city,
    state,
    country,
  }) {
    this.name = companyChain[0].slice(1)
    this.address_line_1 = address_line_1
    this.address_line_2 = address_line_2
    this.postcode = postalcode
    this.city = city
    this.state = state
    this.country = country
  }
}
