/**
 * Helper: Sort an array of objects by a given property.
 * Returns a new sorted array.
 *
 * @param {Array} arr - The array to sort.
 * @param {string} key - The property key to sort by.
 * @returns {Array} A new sorted array.
 */
function sortByKey(arr, key = 'key') {
  return arr.slice().sort((a, b) => {
    const aKey = a[key] || ''
    const bKey = b[key] || ''
    return String(aKey).localeCompare(String(bKey))
  })
}

/**
 * Compares two arrays of file objects by comparing only their IDs.
 *
 * @param {Array} storedFiles - Array of file objects from storage.
 * @param {Array} freshFiles - Array of file objects from the fresh part.
 * @returns {boolean} True if both arrays contain the same file IDs (ignoring order).
 */
function compareFilesIds(storedFiles = [], freshFiles = []) {
  const storedIds = storedFiles
    .map((file) => file.id)
    .sort((a, b) => String(a).localeCompare(String(b)))
  const freshIds = freshFiles
    .map((file) => file.id)
    .sort((a, b) => String(a).localeCompare(String(b)))
  return JSON.stringify(storedIds) === JSON.stringify(freshIds)
}

/**
 * Compares specific properties of two part objects:
 * - attributes (array, sorted by "key")
 * - float_attributes (array, sorted by "key")
 * - files (compared by file ids)
 * - name (string)
 * - updated_at (string)
 *
 * @param {Object} storedPart - The part object from storage.
 * @param {Object} freshPart - The freshly fetched part object.
 * @returns {boolean} True if all compared properties are equal.
 */
export function comparePartAttributes(storedPart, freshPart) {
  if (!storedPart || !freshPart) return false

  // Compare simple strings.
  const nameEqual = storedPart.name === freshPart.name
  const updatedAtEqual = storedPart.updated_at === freshPart.updated_at

  // Compare the 'attributes' arrays (sorted by 'key').
  const storedAttributes = sortByKey(storedPart.attributes || [], 'key')
  const freshAttributes = sortByKey(freshPart.attributes || [], 'key')
  const attributesEqual =
    JSON.stringify(storedAttributes) === JSON.stringify(freshAttributes)

  // Compare the 'float_attributes' arrays (sorted by 'key').
  const storedFloatAttributes = sortByKey(
    storedPart.float_attributes || [],
    'key',
  )
  const freshFloatAttributes = sortByKey(
    freshPart.float_attributes || [],
    'key',
  )
  const floatAttributesEqual =
    JSON.stringify(storedFloatAttributes) ===
    JSON.stringify(freshFloatAttributes)

  // Compare the 'files' arrays by checking if the corresponding IDs are the same.
  const filesEqual = compareFilesIds(storedPart.files, freshPart.files)

  return (
    nameEqual &&
    updatedAtEqual &&
    attributesEqual &&
    floatAttributesEqual &&
    filesEqual
  )
}
