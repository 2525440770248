import axios from 'axios'
import { notification } from 'antd'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import tokenInterceptor from '$api/tokenInterceptor'
import { updateStatusAttributes } from '$utils'
import { attachPendingRequestsInterceptors } from './pendingRequestsInterceptor'

export let evoClient

export const configureClient = (url) => {
  // Allow to override whatever was given in `url` by the URL set as
  //   environment variable REACT_APP_EVOBUS_CLIENT
  const clientUrlOverride =
    window.REACT_APP_EVOBUS_CLIENT || process.env.REACT_APP_EVOBUS_CLIENT
  if (clientUrlOverride) {
    url = clientUrlOverride
  }

  evoClient = axios.create({
    baseURL: url,
  })

  // add token to all requests
  evoClient.interceptors.request.use(tokenInterceptor)

  // track pending requests to warn user before leaving site
  attachPendingRequestsInterceptors(evoClient)
}

// static method which requires the client URL as a param as we typically want to check version before client is fully configured
export const fetchVersion = async (url) => {
  const { data } = await axios.get(`${url}/version/`)
  return data
}

export const fetchTheme = async ({ queryKey: [_, themeUrl] }) => {
  if (!themeUrl) {
    return {}
  }

  const { data } = await axios.get(`${themeUrl}/theme.json`)
  return data
}

export async function fetchAllParts() {
  const res = await evoClient.get('/parts/')
  return res?.data
}

const fetchPartsBaseQuery = async ({
  queryKey: [
    _,
    { pageNr, pageSize, sorting = {}, filters = {}, groupId },
    acceptHeader = 'application/json',
  ],
}) => {
  const config = {
    headers: {
      accept: acceptHeader,
    },
  }

  const { key = 'id_part_client', order = 1 } = sorting
  let params = {
    page: pageNr,
    size: pageSize,
    sort_by:
      key === 'vector8'
        ? `vector8~${sorting.referencePartId}`
        : `${order === -1 ? '-' : ''}${key}`,
  }

  const paramsString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  const filtersArray = Object.values(filters).flat()
  if (typeof groupId !== 'undefined') {
    filtersArray.push({
      parameter: 'partlist_id',
      logical_operator: '=',
      value: groupId,
    })
  }
  const page = filtersArray.length
    ? await evoClient.post(
        `/parts/filter/?${paramsString}`,
        filtersArray,
        config,
      )
    : await evoClient.get(`/parts/?${paramsString}`, config)

  return page
}

export const fetchParts = async (props) => {
  const { data } = await fetchPartsBaseQuery(props)
  return data
}

export const fetchPart = async ({ queryKey: [_, partId] }) => {
  const res = await evoClient.get(`/parts/${partId}`)
  return res?.data
}

export const fetchPartsByIds = async ({ queryKey: [_, partIds] }) => {
  const paramsString = partIds.map((partId) => `part_ids=${partId}`).join('&')

  const { data } = await evoClient.get(`/parts/?${paramsString}`)
  return data
}

export const fetchPartsAsBlob = async (props) => {
  const { headers, data } = await fetchPartsBaseQuery(props)
  const contentType = headers.getContentType()

  let blobData = data
  if (contentType === 'application/json') {
    blobData = JSON.stringify(data, null, 2)
  }

  const blob = new Blob([blobData], { type: contentType })
  return blob
}

export const fetchGroupParts = async ({ queryKey }) => {
  const [_, pageNr, pageSize, sorting = {}, groupId] = queryKey
  const { key = 'id_part_client', order = 1 } = sorting
  const { data } = await evoClient.post(
    `/parts/filter/?page=${pageNr}&size=${pageSize}&order=${order}&key=${key}`,
    [
      {
        parameter: 'partlist_id',
        logical_operator: '=',
        value: groupId,
      },
    ],
  )
  return data
}

export async function fetchAllGroups() {
  const res = await evoClient.get('/parts-lists/')
  return res?.data
}

export async function postGroup({
  list_name,
  description,
  filter_description,
}) {
  return evoClient.post(
    '/parts-lists/',
    {
      list_name,
      description,
      filter_description,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export async function putGroup({
  groupId,
  list_name,
  description,
  filter_description,
}) {
  return evoClient.put(
    `/parts-lists/${groupId}`,
    {
      list_name,
      description,
      filter_description,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export async function putPartsToGroup({ partsIds, groupId }) {
  return evoClient.put(`/parts-lists/${groupId}/parts`, partsIds, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function deleteGroup(groupId) {
  return evoClient.delete(`/parts-lists/${groupId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const postFilter = `/parts/filter/`

export async function postPart(partToAdd) {
  return evoClient.post(`/parts/`, partToAdd, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function postMultipleParts(partsToAdd) {
  return evoClient.post(`/parts/multiple/`, partsToAdd, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const useDeleteParts = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (selectedParts) => {
      return await evoClient.delete(
        '/parts/multiple/?ids=' + Object.keys(selectedParts).join('&ids='),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['parts'])
      notification.success({
        message: 'Success',
        description: 'Parts deleted successfully',
      })
    },
    onError: () => {
      queryClient.invalidateQueries(['parts'])
    },
  })
}

export const putPart = `/parts/`

export const postStl = async ({ partId, file, onProgress }) => {
  const formData = new FormData()
  formData.append('file', file)

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 })
    },
  }

  const { data } = await evoClient.post(
    `/parts/${partId}/stl/`,
    formData,
    config,
  )
  return data
}

export const parts = `/parts/`

export async function getPartPng(part_id) {
  const { data } = await evoClient.get(`/parts/${part_id}/png/`, {
    responseType: 'blob',
  })
  return data
}

export async function getPartStl(part_id) {
  const { data } = await evoClient.get(`/parts/${part_id}/stl/`, {
    responseType: 'blob',
  })
  return data
}

export async function getPartStlHead(part_id) {
  const { data } = await evoClient.head(`/parts/${part_id}/stl/`)
  return data
}

export const putStatusAttributes = ({ partsToUpdate, serviceName }) => {
  if (partsToUpdate && serviceName) {
    const editedPart = updateStatusAttributes(
      partsToUpdate,
      'status_attributes',
      serviceName,
      '1',
    )
    return evoClient.put(`/parts/${editedPart.id}`, editedPart)
  } else {
    console.error('Part status could not be updated')
  }
}

export const updateStatusAttributesAfterOrder = (partsToUpdate) => {
  if (partsToUpdate.part) {
    const editedPart = updateStatusAttributes(
      updateStatusAttributes(
        partsToUpdate.part,
        'status_attributes',
        'Request AM Offer',
        '1',
      ),
      'status_attributes',
      'Order Part',
      '1',
    )
    return evoClient.put(`/parts/${editedPart.db_id_client}`, editedPart)
  }
}

export const usePostTechnicalDrawing = (
  queryKeyParts,
  queryKeyTechnicalDrawing,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ partId, file, onProgress }) => {
      const formData = new FormData()
      formData.append('file', file)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }

      const { data } = await evoClient.post(
        `/parts/${partId}/technical-drawing/`,
        formData,
        config,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeyParts])
      queryClient.invalidateQueries([queryKeyTechnicalDrawing])
    },
    onError: () => {
      console.error('Could not upload technical drawing')
    },
  })
}

export async function getTechnicalDrawing(partId) {
  try {
    const response = await evoClient.get(
      `/parts/${partId}/technical-drawing/`,
      {
        responseType: 'blob',
      },
    )

    const mimeType = response.headers['content-type']
    const contentDisposition = response.headers['content-disposition']

    let filename = null
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/)
      if (match && match[1]) {
        filename = match[1]
      }
    }

    return {
      data: response.data,
      filename: filename,
      type: mimeType,
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null
    }
    throw error
  }
}

export const useDeleteTechnicalDrawing = (
  queryKeyParts,
  queryKeyTechnicalDrawing,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (partId) => {
      return await evoClient.delete(`/parts/${partId}/technical-drawing/`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeyParts])
      queryClient.invalidateQueries([queryKeyTechnicalDrawing])
    },
  })
}
