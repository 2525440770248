import { useState, useEffect } from 'react'
import { InputNumber, Select, Button, Switch, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import DatePicker from '$components/DatePicker'
import Spinner, { CenteredSpinner } from '$components/Spinner'
import Form from '$components/Form'
import { PartNoBg } from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'
import { StyledTable, StyledCloseIcon } from './styledComponents'
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons'
import TruncateText from '$components/TruncateText'
import ConfigOrderPartDetailView from './ConfigOrderPartDetailView'
import dayjs from 'dayjs'

const { useToken } = theme

const DESIRED_DELIVERY_DATE = 'desired_delivery_date'
const ALLOW_PARTIAL_DELIVERY = 'allow_partial_delivery'
const ALLOW_OTHER_SUPPLIERS = 'allow_other_suppliers'
const ALLOW_SUBCONTRACTORS = 'allow_subcontractors'

/*
 * Attach this function to Forms onValuesChanged method
 */
export const updateMainAmount = (form, changedValues, allValues) => {
  // attempt to retrieve id of part for which amount was changed in a subOrder
  let partId
  try {
    // filter out empty elements
    // then attempt to select first item since only one item at a time can change
    partId = Object.keys(
      changedValues.subOrders.filter((el) => el)[0].amounts,
    )[0]
  } catch {
    partId = null
  }

  const subOrders = allValues.subOrders?.filter(
    (subOrder) => subOrder !== undefined,
  )

  if (partId) {
    // update main amount for part with sum of all subOrder amounts for said part
    form.setFieldsValue({
      amounts: {
        [partId]: subOrders
          .map((subOrder) => subOrder.amounts[partId])
          .reduce((totalAmount, amount) => totalAmount + amount, 0),
      },
    })
  }
}

/*
 * Renders form fields wrapped with antd Form.Item.
 * Render this component within an antd Form, to access values via form methods (e.g. onFinish).
 */
const OrderPartsTable = ({
  parts,
  materials,
  processingParts = [],
  wizardData,
  form,
}) => {
  const { t } = useTranslation()
  const { token } = useToken()
  const [expandedRowKeys, setExpandedRowKeys] = useState(() =>
    parts && parts.length > 0 ? [parts[0].id] : [],
  )
  const mainAmounts = Form.useWatch('amounts')
  const desiredDeliveryDate = Form.useWatch(DESIRED_DELIVERY_DATE)
  const allowPartialDelivery = Form.useWatch(ALLOW_PARTIAL_DELIVERY)
  const allowSubContractors = Form.useWatch(ALLOW_SUBCONTRACTORS)
  const subOrdersValues = Form.useWatch('subOrders')?.filter(
    (subOrder) => subOrder !== undefined,
  )

  const showAllowOtherSuppliersRow =
    allowSubContractors ||
    subOrdersValues?.some((suborder) => suborder[ALLOW_SUBCONTRACTORS])

  const [subOrders, setSubOrders] = useState([])
  const META_ROWS = [
    DESIRED_DELIVERY_DATE,
    ALLOW_PARTIAL_DELIVERY,
    ALLOW_SUBCONTRACTORS,
    ...(showAllowOtherSuppliersRow ? [ALLOW_OTHER_SUPPLIERS] : []),
  ]

  // Pre-populate subOrders state + form when wizardData changes
  useEffect(() => {
    if (
      wizardData?.configFormData?.allow_partial_delivery &&
      Array.isArray(wizardData?.configFormData?.subOrders)
    ) {
      // 1) Build an array of subOrder IDs to render as columns
      const loadedSubOrders = wizardData.configFormData.subOrders.map(
        (_, index) => ({
          id: index,
        }),
      )
      setSubOrders(loadedSubOrders)

      // 2) Prepare the suborder object
      const subOrdersFormValues = wizardData.configFormData.subOrders.reduce(
        (acc, subOrder, index) => {
          acc[index] = {
            ...subOrder,
            [DESIRED_DELIVERY_DATE]: subOrder[DESIRED_DELIVERY_DATE]
              ? dayjs(subOrder[DESIRED_DELIVERY_DATE])
              : undefined,
          }
          return acc
        },
        {},
      )

      // 3) handle the top-level
      form.setFieldsValue({
        [ALLOW_PARTIAL_DELIVERY]:
          wizardData.configFormData[ALLOW_PARTIAL_DELIVERY],
        [ALLOW_SUBCONTRACTORS]: wizardData.configFormData[ALLOW_SUBCONTRACTORS],
        subOrders: subOrdersFormValues,
      })
    } else {
      // If subOrders is empty
      setSubOrders([])
      form.setFieldsValue({
        [ALLOW_PARTIAL_DELIVERY]: false,
        [ALLOW_SUBCONTRACTORS]: false,
        subOrders: {},
      })
    }
  }, [wizardData, form])

  const hasSubOrders = allowPartialDelivery && subOrders.length > 0

  const recordIsPart = (record) => !recordIsMetaRow(record) && record.id
  const recordIsMetaRow = (record) => META_ROWS.includes(record)

  const handleAdd = () => {
    setSubOrders([
      ...subOrders,
      {
        // 0 or increment last id
        id: subOrders.length === 0 ? 0 : subOrders.slice(-1).pop().id + 1,
      },
    ])
  }

  const handleRemove = (subOrderId) => {
    setSubOrders(subOrders.filter((subOrder) => subOrder.id !== subOrderId))
  }

  const renderAmountColumn = (record, subOrderId) => {
    const isSubOrderCol = Number.isInteger(subOrderId)
    const isMainCol = !isSubOrderCol

    const generateNamePath = (itemName) =>
      recordIsMetaRow(record) && isSubOrderCol
        ? ['subOrders', subOrderId, itemName]
        : itemName

    // handle meta rows
    if (record === DESIRED_DELIVERY_DATE) {
      // only show for main column if there are no subOrders
      if (!hasSubOrders || isSubOrderCol) {
        return (
          <Form.Item
            name={generateNamePath(DESIRED_DELIVERY_DATE)}
            initialValue={
              isSubOrderCol && subOrders.length === 1
                ? dayjs(desiredDeliveryDate)
                : undefined
            }
          >
            <DatePicker
              style={{ margin: '-6px 0', width: '100%', maxWidth: '150px' }}
              disabledDate={(d) => !d || d.isBefore(new Date())}
            />
          </Form.Item>
        )
      }
    }

    if (record === ALLOW_PARTIAL_DELIVERY) {
      return (
        <Form.Item
          name={generateNamePath(ALLOW_PARTIAL_DELIVERY)}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      )
    }
    if (record === ALLOW_SUBCONTRACTORS) {
      return (
        <Form.Item
          name={generateNamePath(ALLOW_SUBCONTRACTORS)}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      )
    }
    if (record === ALLOW_OTHER_SUPPLIERS) {
      return (
        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue(generateNamePath(ALLOW_SUBCONTRACTORS)) && (
              <Form.Item
                name={generateNamePath(ALLOW_OTHER_SUPPLIERS)}
                valuePropName="checked"
                initialValue={true}
                shouldUpdate
              >
                <Switch />
              </Form.Item>
            )
          }
        </Form.Item>
      )
    }

    // handle actual amount col

    if (recordIsPart(record)) {
      return (
        <Form.Item
          name={
            isSubOrderCol
              ? ['subOrders', subOrderId, 'amounts', record.id]
              : ['amounts', record.id]
          }
          initialValue={
            isMainCol ? 1 : subOrders.length === 1 ? mainAmounts[record.id] : 0
          }
          rules={[
            {
              required: true,
              message: t('service.order_part.amount_required'),
              min: isMainCol ? 1 : 0,
              type: 'integer',
            },
            {
              validator: (_, value) => {
                if (value > 100000) {
                  return Promise.reject(t('service.order_part.amount_exceeded'))
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%', maxWidth: '150px' }}
            size="small"
            disabled={isMainCol && hasSubOrders}
            addonAfter={isMainCol && hasSubOrders ? '∑' : undefined}
          />
        </Form.Item>
      )
    }
  }

  const basicColumns = [
    {
      title: t('Part No'),
      dataIndex: 'id_part_client',
      width: '160px',
      render: (title, record) => {
        if (record === DESIRED_DELIVERY_DATE) {
          return t('service.order_part.desired_delivery_date')
        }
        if (record === ALLOW_PARTIAL_DELIVERY) {
          return t('service.order_part.allow_partial_delivery')
        }
        if (record === ALLOW_SUBCONTRACTORS) {
          return t('service.order_part.allow_subcontractors')
        }
        if (record === ALLOW_OTHER_SUPPLIERS) {
          return t('service.order_part.allow_other_suppliers')
        }
        return <PartNoBg>{title}</PartNoBg>
      },
      fixed: 'left',
      onCell: (record) => ({
        colSpan: recordIsMetaRow(record) ? 3 : 1,
        align: recordIsMetaRow(record) ? 'right' : undefined,
      }),
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      fixed: 'left',
      onCell: (record) => ({
        colSpan: recordIsMetaRow(record) ? 0 : 1,
        rowSpan: recordIsMetaRow(record) ? 0 : 1,
      }),
      render: (text, record) => (
        <>
          <TruncateText text={text} />
          {processingParts.includes(record.id) ? <Spinner size="small" /> : ''}
        </>
      ),
      ellipsis: true,
    },
    {
      title: 'Material',
      fixed: 'left',
      render: (record) => {
        if (recordIsPart(record)) {
          const getMaterialIdWorkaround = () => {
            const materialName = record.mergedattributes.find(
              (attr) => attr.key === 'Material',
            ).value
            return materials.find((material) => material.name === materialName)
              ?.id
          }

          return (
            <TruncateText
              text={
                <Form.Item
                  name={['materials', record.id]}
                  initialValue={getMaterialIdWorkaround()}
                  rules={[
                    {
                      required: true,
                      message: t('service.order_part.material_required'),
                    },
                  ]}
                >
                  <Select
                    style={{ margin: '-6px 0', width: '100%' }}
                    size="small"
                    options={materials.map((material) => ({
                      label: material.name,
                      value: material.id,
                      disabled: !material.printerAvailable,
                    }))}
                  />
                </Form.Item>
              }
            />
          )
        }
      },
      onCell: (record) => ({
        colSpan: recordIsMetaRow(record) ? 0 : 1,
        rowSpan: recordIsMetaRow(record) ? 0 : 1,
      }),
      ellipsis: true,
    },
    {
      title: t('service.order_part.quantity'),
      render: (record) => renderAmountColumn(record),
    },
  ]

  if (!materials || (materials && materials.length === 0)) {
    return <CenteredSpinner />
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  const iconProps = {
    size: '15',
    color: token.colorPrimary,
    style: { margin: '0px -7px -3px -5px' },
  }

  return (
    <StyledTable
      size="middle"
      columns={[
        ...basicColumns,
        // render subOrders if allowPartialDelivery is selected
        ...(allowPartialDelivery
          ? [
              ...subOrders.map((subOrder, index) => ({
                title: (
                  <>
                    {`${t('service.order_part.batch')} ${index + 1}`}
                    <StyledCloseIcon
                      onClick={() => handleRemove(subOrder.id)}
                    />
                  </>
                ),
                children: [
                  {
                    title: t('service.order_part.quantity'),
                    render: (record) => renderAmountColumn(record, subOrder.id),
                  },
                ],
              })),
            ]
          : []),
        // and show add subOrder Button
        ...(allowPartialDelivery
          ? [
              {
                // show button in table header
                title: (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={handleAdd}
                  />
                ),
                width: 50,
                fixed: 'right',
                render: () => {}, // don't render anything in table cells
              },
            ]
          : []),
      ]}
      style={{ width: '100%' }}
      dataSource={[...parts, ...META_ROWS]}
      scroll={{ x: '100%' }}
      pagination={true}
      rowClassName={(record) =>
        recordIsMetaRow(record) ? 'meta_row' : undefined
      }
      rowKey={(record) => {
        if (recordIsPart(record)) {
          return record.id
        }
        if (recordIsMetaRow(record)) {
          return record
        }
      }}
      expandable={{
        expandIconAsCell: false,
        showExpandColumn: true,
        expandedRowRender: (record) => (
          <ConfigOrderPartDetailView
            record={record}
            materials={materials}
            wizardData={wizardData}
          />
        ),
        rowExpandable: (record) => !!record.id,
        expandedRowKeys: expandedRowKeys,
        onExpand: onTableRowExpand,
        expandIcon: ({ expanded, onExpand, record }) => {
          if (!record.id) {
            // Do not render anything for meta rows.
            return null
          }
          return (
            <span
              onClick={(e) => onExpand(record, e)}
              style={{ cursor: 'pointer' }}
            >
              {expanded ? (
                <UilMinusCircle {...iconProps} />
              ) : (
                <UilPlusCircle {...iconProps} />
              )}
            </span>
          )
        },
      }}
    />
  )
}

export default OrderPartsTable
