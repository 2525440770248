import React, { useRef, useState } from 'react'
import Tooltip from '$components/Tooltip'
import {
  StyledTruncate,
  StyledTruncateClamp,
} from '$components/TruncateText/TruncateStyled'

const TruncateText = ({ text, useLineClamp = false }) => {
  const textRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const StyledComponent = useLineClamp ? StyledTruncateClamp : StyledTruncate

  const handleOverflow = () => {
    if (textRef.current) {
      const isOverflowing = useLineClamp
        ? textRef.current.scrollHeight > textRef.current.offsetHeight
        : textRef.current.scrollWidth > textRef.current.offsetWidth

      setShowTooltip(isOverflowing)
    }
  }

  return (
    <Tooltip title={showTooltip ? text : ''}>
      <StyledComponent
        ref={textRef}
        onMouseEnter={handleOverflow}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {text}
      </StyledComponent>
    </Tooltip>
  )
}

export default TruncateText
