import styled from 'styled-components'
import { StyledTable as DefaultStyledTable } from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'
import { CloseCircleOutlined } from '@ant-design/icons'
import Form from '$components/Form'

export const StyledTable = styled(DefaultStyledTable)`
  tr.meta_row > td {
    background-color: #f6f6f6;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-table-expanded-row-fixed {
    background-color: rgb(250, 250, 250);
    padding: 25px 50px;
  }
`

export const StyledCloseIcon = styled(CloseCircleOutlined)`
  position: absolute;
  right: 1em;
  top: 1.25em;
`

export const StyledItem = styled(Form.Item)`
  margin-bottom: 5px !important;
  .ant-form-item-control {
    flex: auto;
    min-width: 0;
    max-width: 100%;
  }
  .ant-form-item-vertical .ant-form-item-label {
    margin: 0;
    padding: 0;
  }
  .ant-col {
    max-width: 100% !important;
  }
`
