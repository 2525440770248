import styled from 'styled-components'
import { Table } from 'antd'

export const StyledPeriods = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  :hover {
    background: #f5f5f5;
  }
`

export const StyledLink = styled.span`
  cursor: pointer;
  width: fit-content;
`

export const StyledTable = styled(Table)`
  /* Apply base table styles */
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
    cursor: default;
  }

  /* Cursor for main table rows */
  .ant-table-tbody > tr.ant-table-row {
    background-color: white;
    cursor: pointer;
  }

  /* Additional styling */
  th::before {
    display: none;
  }
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td:hover {
    border-bottom: none;
    background: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: fit-content;
  }
`
export const StyledSubTable = styled(StyledTable)`
  .ant-table-tbody {
    ${(props) =>
      props.expandable
        ? `
        > tr.ant-table-row {
          cursor: pointer;
        }
      `
        : `> tr.ant-table-row {
          cursor: default;
        }`}
  }
`
