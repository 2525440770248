// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scrollbar-gutter: stable;
}
body {
  margin: 0;
}
body * {
  outline: none;
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/assets/global-styles.less"],"names":[],"mappings":"AAGA;EACE,wBAAA;AAFF;AAKA;EAaE,SAAA;AAfF;AAEA;EAEI,aAAA;EACA,kGAAA;AADJ","sourcesContent":["// @import '~antd/es/style/themes/default.less';\n// @import 'defaultTheme.json';\n\nhtml {\n  scrollbar-gutter: stable;\n}\n\nbody {\n  * {\n    outline: none;\n    font-family:\n      Segoe UI,\n      Frutiger,\n      Frutiger Linotype,\n      Dejavu Sans,\n      Helvetica Neue,\n      Arial,\n      sans-serif;\n  }\n\n  margin: 0;\n}\n\n@primary-color: #00677F;@primary-color-hover: #00677F;@primary-color-active: #fafdfd;@primary-color-outline: #00677F;@font-size-base: 12px;@border-color-base: #bfbfbf;@border-color-inverse: #000000;@progress-default-color: #F1B44C;@processing-color: #00677F;@text-color: #495057;@heading-color: #495057;@table-selected-row-bg: #fafdfd;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
