import { useState, useEffect } from 'react'
import { UilPrint, UilMapMarker } from '@iconscout/react-unicons'
import {
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Button,
  Space,
  Switch,
  Divider,
  notification,
} from 'antd'
import { Title, ExpandableTableCard, MetaInfo } from './styledComponents'
import { fetchAllMaterials } from '$api/evoAPIs'
import { evoClient, putPart } from '$api/client'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { resetFcOnAttributeChange } from '$utils'
import CustomInputNumber from '$components/CustomInputNumber'
import Form from '$components/Form'
import { dateWithTimeRenderer } from '$utils/i18n'

const PartDetailForm = ({ record, showEditDates = false }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const [materials, setMaterials] = useState([])
  const [fcAttrHasChanged, setFcAttrHasChanged] = useState(false)

  const updateGroupMutation = useMutation(
    () => {
      return evoClient.put(putPart + record.id, JSON.stringify(record), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: async () => {
        try {
          openNotification('top', t('part_updated'))

          const feasibilityCheckAttr = record.attributes.find(
            (attr) => attr.key === 'Feasibility Check',
          )?.value
          if (
            fcAttrHasChanged &&
            (feasibilityCheckAttr === 'true' ||
              feasibilityCheckAttr === 'false')
          ) {
            openWarning('top', t('service.feasibility_check.fc_reset_message'))

            await resetFcOnAttributeChange(record)
            await Promise.all([
              queryClient.invalidateQueries(['bookedServices']),
              queryClient.invalidateQueries(['orderPartsLists']),
              queryClient.invalidateQueries(['allparts']),
            ])
          }

          await queryClient.invalidateQueries(['parts'])
          form.resetFields()
        } catch (error) {
          console.error('Error during onSuccess:', error)
        }
      },
    },
  )

  useQuery(['materials'], fetchAllMaterials, {
    onSuccess: (response) => {
      setMaterials(
        response.map((item) => {
          return {
            value: item.name,
            label: item.name,
          }
        }),
      )
    },
    onError: (e) => {
      console.error(e)
    },
  })

  let partwidth = record.float_attributes.find((x) => x.key === 'Width')?.value
  let partheight = record.float_attributes.find(
    (x) => x.key === 'Height',
  )?.value
  let partdepth = record.float_attributes.find((x) => x.key === 'Depth')?.value
  let partweight = record.float_attributes.find(
    (x) => x.key === 'Weight',
  )?.value
  let partmaterial = record.attributes.find((x) => x.key === 'Material')?.value
  let partprice = record.float_attributes.find((x) => x.key === 'Price')?.value
  let partprice_am = record.float_attributes.find(
    (x) => x.key === 'Price AM',
  )?.value
  let partprinting_license = record.attributes.find(
    (x) => x.key === 'Printing License',
  )?.value
  let partfeasibility_check = record.attributes.find(
    (x) => x.key === 'Feasibility Check',
  )?.value
  let parteco_suit = record.attributes.find(
    (x) => x.key === 'Economical Suitability',
  )?.value
  let parttech_suit = record.attributes.find(
    (x) => x.key === 'Technical Suitability',
  )?.value

  const isTrue = (value) => {
    if (typeof value === 'string') {
      value = value.trim().toLowerCase()
    }
    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
        return true
      default:
        return false
    }
  }

  const onFinish = (values) => {
    // check if form input exists and if part.attribute bzw part.float_attribute exists,
    // if the first not exist so nothing, if the first exists and the second doesn't, insert new attribute to record
    if (values.id_part_client !== undefined) {
      record.id_part_client = values.id_part_client
    }
    if (values.name !== undefined) {
      record.name = values.name
    }
    if (values.B !== undefined && partwidth !== undefined) {
      record.float_attributes = record.float_attributes.map((obj) =>
        obj.key === 'Width' ? { ...obj, value: values.B } : obj,
      )
      if (values.B !== partwidth) {
        setFcAttrHasChanged(true)
      }
    } else if (partwidth === undefined && values.B !== undefined) {
      record.float_attributes.push({
        key: 'Width',
        value: values.B,
        description: null,
      })
    }
    if (values.H !== undefined && partheight !== undefined) {
      record.float_attributes = record.float_attributes.map((obj) =>
        obj.key === 'Height' ? { ...obj, value: values.H } : obj,
      )
      if (values.H !== partheight) {
        setFcAttrHasChanged(true)
      }
    } else if (partheight === undefined && values.H !== undefined) {
      record.float_attributes.push({
        key: 'Height',
        value: values.H,
        description: null,
      })
    }
    if (values.T !== undefined && partdepth !== undefined) {
      record.float_attributes = record.float_attributes.map((obj) =>
        obj.key === 'Depth' ? { ...obj, value: values.T } : obj,
      )
      if (values.T !== partdepth) {
        setFcAttrHasChanged(true)
      }
    } else if (partdepth === undefined && values.T !== undefined) {
      record.float_attributes.push({
        key: 'Depth',
        value: values.T,
        description: null,
      })
    }
    if (values.weight !== undefined && partweight !== undefined) {
      if (values.weight !== null) {
        record.float_attributes = record.float_attributes.map((obj) =>
          obj.key === 'Weight' ? { ...obj, value: values.weight } : obj,
        )
      } else {
        record.float_attributes = record.float_attributes.filter(
          (value) => value.key !== 'Weight',
        )
      }
      if (values.weight !== partweight) {
        setFcAttrHasChanged(true)
      }
    } else if (partweight === undefined && values.weight !== undefined) {
      record.float_attributes.push({
        key: 'Weight',
        value: values.weight,
        description: null,
      })
    }

    if (partmaterial !== undefined) {
      if (values.material !== undefined) {
        record.attributes = record.attributes.map((obj) =>
          obj.key === 'Material'
            ? { ...obj, value: values.material.toString() }
            : obj,
        )
      } else {
        // remove material from attributes if select form was cleared
        record.attributes = record.attributes.filter(
          (obj) => obj.key !== 'Material',
        )
      }
      if (values.material !== partmaterial) {
        setFcAttrHasChanged(true)
      }
    } else if (partmaterial === undefined && values.material !== undefined) {
      record.attributes.push({
        key: 'Material',
        value: values.material.toString(),
        description: null,
      })
    }

    if (values.price !== undefined && partprice !== undefined) {
      record.float_attributes = record.float_attributes.map((obj) =>
        obj.key === 'Price' ? { ...obj, value: values.price } : obj,
      )
    } else if (partprice === undefined && values.price !== undefined) {
      record.float_attributes.push({
        key: 'Price',
        value: values.price,
        description: null,
      })
    }
    if (values.price_am !== undefined && partprice_am !== undefined) {
      record.float_attributes = record.float_attributes.map((obj) =>
        obj.key === 'Price AM' ? { ...obj, value: values.price_am } : obj,
      )
    } else if (partprice_am === undefined && values.price_am !== undefined) {
      record.float_attributes.push({
        key: 'Price AM',
        value: values.price_am,
        description: null,
      })
    }
    if (
      values.printing_license !== undefined &&
      partprinting_license !== undefined
    ) {
      record.attributes = record.attributes.map((obj) =>
        obj.key === 'Printing License'
          ? { ...obj, value: values.printing_license.toString() }
          : obj,
      )
    } else if (
      partprinting_license === undefined &&
      values.printing_license !== undefined
    ) {
      record.attributes.push({
        key: 'Printing License',
        value: values.printing_license.toString(),
        description: null,
      })
    }
    if (
      values.feasibility_check !== undefined &&
      partfeasibility_check !== undefined
    ) {
      record.attributes = record.attributes.map((obj) =>
        obj.key === 'Feasibility Check'
          ? { ...obj, value: values.feasibility_check.toString() }
          : obj,
      )
    } else if (
      partfeasibility_check === undefined &&
      values.feasibility_check !== undefined
    ) {
      record.attributes.push({
        key: 'Feasibility Check',
        value: values.feasibility_check.toString(),
        description: null,
      })
    }
    if (values.eco_suit !== undefined && parteco_suit !== undefined) {
      record.attributes = record.attributes.map((obj) =>
        obj.key === 'Economical Suitability'
          ? { ...obj, value: values.eco_suit.toString() }
          : obj,
      )
    } else if (parteco_suit === undefined && values.eco_suit !== undefined) {
      record.attributes.push({
        key: 'Economical Suitability',
        value: values.eco_suit.toString(),
        description: null,
      })
    }
    if (values.tech_suit !== undefined && parttech_suit !== undefined) {
      record.attributes = record.attributes.map((obj) =>
        obj.key === 'Technical Suitability'
          ? { ...obj, value: values.tech_suit.toString() }
          : obj,
      )
    } else if (parttech_suit === undefined && values.tech_suit !== undefined) {
      record.attributes.push({
        key: 'Technical Suitability',
        value: values.tech_suit.toString(),
        description: null,
      })
    }

    updateGroupMutation.mutate()
  }

  const onReset = () => {
    openNotification('top', 'Änderungen verworfen')
    form.resetFields()
  }

  const openNotification = (placement, message) => {
    notification.success({
      message,
      description: ``,
      placement,
    })
  }

  const openWarning = (placement, message) => {
    notification.warning({
      message,
      description: ``,
      placement,
    })
  }

  useEffect(() => {
    form.resetFields()
  }, [form])

  return (
    <ExpandableTableCard>
      {showEditDates && (
        <MetaInfo>
          <span className="updated_at">
            {t('Modification Date')}:{' '}
            {dateWithTimeRenderer(t)(record.updated_at)}
          </span>
          <span className="created_at">
            {t('Creation Date')}: {dateWithTimeRenderer(t)(record.created_at)}
          </span>
        </MetaInfo>
      )}
      <Title>{t('parts_master_data.edit_part')}</Title>
      <Form
        layout="horizontal"
        size="small"
        onFinish={onFinish}
        onReset={onReset}
        form={form}
        className="partdetailform"
      >
        <Row className="row">
          <Col flex="1 0 25%" className="formcolumn">
            <Form.Item
              label={t('Part No')}
              name="id_part_client"
              initialValue={record.id_part_client}
              width="160px"
            >
              <Input
                disabled={true}
                variant="borderless"
                className="white-bg"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('marketplace.detail_form.name.missing'),
                },
              ]}
              label={t('Part Name')}
              name="name"
              initialValue={record.name}
            >
              <Input variant="borderless" className="white-bg" />
            </Form.Item>
            <Space.Compact block>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('marketplace.detail_form.width.missing'),
                  },
                ]}
                label={t('Dimensions')}
                name="B"
                layout="inline"
                initialValue={partwidth}
              >
                <InputNumber
                  prefix="B"
                  min={1}
                  className="dwidth white-bg"
                  controls={false}
                  variant="borderless"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('marketplace.detail_form.depth.missing'),
                  },
                ]}
                name="T"
                initialValue={partdepth}
              >
                <InputNumber
                  prefix="T"
                  min={1}
                  className="dwidth white-bg"
                  controls={false}
                  variant="borderless"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('marketplace.detail_form.height.missing'),
                  },
                ]}
                name="H"
                initialValue={partheight}
              >
                <InputNumber
                  addonAfter="mm"
                  prefix="H"
                  min={1}
                  className="dwidth white-bg"
                  controls={false}
                  variant="borderless"
                />
              </Form.Item>
            </Space.Compact>
            <Form.Item
              label={t('Weight')}
              name="weight"
              initialValue={partweight}
            >
              <InputNumber
                addonAfter="g"
                min={1}
                variant="borderless"
                className="white-bg"
                style={{ marginRight: -30 }}
              />
            </Form.Item>
            <Form.Item
              label={t('Material')}
              name="material"
              initialValue={partmaterial}
            >
              <Select
                allowClear
                showSearch
                variant="borderless"
                className="white-bg"
                options={materials}
                style={{ minWidth: '66px' }}
              ></Select>
            </Form.Item>
          </Col>
          <Divider type="vertical" className="partsdivider" />
          <Col flex="1 0 25%" className="formcolumn">
            <Form.Item label={t('Price')} name="price" initialValue={partprice}>
              <CustomInputNumber
                addonAfter="€"
                min={0.01}
                max={10000}
                step={0.01}
                variant="borderless"
                className="white-bg"
              />
            </Form.Item>
            <Form.Item
              label={t('Price AM')}
              name="price_am"
              initialValue={partprice_am}
            >
              <CustomInputNumber
                addonAfter="€"
                min={0.01}
                max={10000}
                step={0.01}
                variant="borderless"
                className="white-bg"
              />
            </Form.Item>
          </Col>
          <Divider type="vertical" className="partsdivider" />
          <Col flex="1 0 25%" className="formcolumn">
            <Form.Item label={t('Available Printers')}>
              <Button shape="round">
                <UilPrint size="18" />
                <UilMapMarker size="18" />
              </Button>
            </Form.Item>
            <Form.Item
              label={t('Printing License')}
              style={{ alignItems: 'flex-end' }}
              name="printing_license"
              valuePropName="checked"
            >
              <Switch defaultChecked={isTrue(partprinting_license)} />
            </Form.Item>
            <Form.Item
              label={t('parts_master_data.feasibility_check')}
              name="feasibility_check"
              valuePropName="checked"
            >
              <Switch
                defaultChecked={isTrue(partfeasibility_check)}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              label={t('parts_master_data.economic_suitability')}
              name="eco_suit"
              valuePropName="checked"
            >
              <Switch defaultChecked={isTrue(parteco_suit)} />
            </Form.Item>
            <Form.Item
              label={t('parts_master_data.technical_suitability')}
              name="tech_suit"
              valuePropName="checked"
            >
              <Switch defaultChecked={isTrue(parttech_suit)} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            <Button type="secondary" onClick={onReset}>
              {t('button.discard')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('button.submit_changes')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ExpandableTableCard>
  )
}

export default PartDetailForm
