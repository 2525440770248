import { useOutletContext, Outlet } from 'react-router-dom'
import ApplyTexturing from './ApplyTexturing'
import Applybranding from './Applybranding'
import FeasibilityCheck from './FeasibilityCheck'
import Generate3DModel from './Generate3DModel'

const Bookservice = () => {
  const {
    bookedServiceStatus,
    selectedParts,
    setSelectedParts,
    setCurrent,
    activeGroupName,
    orderToRepeat,
    setOrderToRepeat,
  } = useOutletContext()

  const service = () => {
    switch (bookedServiceStatus[1].id) {
      case '63dcfb10f81b69c6d3a7c42f':
        return (
          <Outlet
            context={{
              bookedServiceStatus,
              selectedParts,
              setSelectedParts,
              setCurrent,
              activeGroupName,
              orderToRepeat,
              setOrderToRepeat,
            }}
          />
        )
      case '63dcfb01f81b69c6d3a7c42e':
        return (
          <ApplyTexturing
            bookedServiceStatus={bookedServiceStatus}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            setCurrent={setCurrent}
            activeGroupName={activeGroupName}
          />
        )
      case '63dcfaf1f81b69c6d3a7c42d':
        return (
          <Applybranding
            bookedServiceStatus={bookedServiceStatus}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            setCurrent={setCurrent}
            activeGroupName={activeGroupName}
          />
        )

      case '6405eed3cebc2701ca6c4967':
        return (
          <Generate3DModel
            bookedServiceStatus={bookedServiceStatus}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            setCurrent={setCurrent}
            activeGroupName={activeGroupName}
          />
        )
      case '63dcfb10f81b69c6d3a7c431':
        return (
          <FeasibilityCheck
            bookedServiceStatus={bookedServiceStatus}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            setCurrent={setCurrent}
            activeGroupName={activeGroupName}
          />
        )
      default:
        throw new Error(`Unknown service id: ${bookedServiceStatus[1].id}`)
    }
  }

  return <>{service()}</>
}

export default Bookservice
