import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigProvider, List, Row, Col, Flex, Switch } from 'antd'
import OrderTechnicalDrawingDownload from '$components/FileDownload/OrderTechnicalDrawingDownload'

const FollowUpTreatmentsView = ({ orderPart }) => {
  const { t } = useTranslation()

  const dataSource = (orderPart?.selected_post_processing_methods ?? []).map(
    (v, i) => (
      <div
        key={`${v.post_processing_method}-${i}`}
        style={{ display: 'block' }}
      >
        {t('received_requests.follow_up_treatments.count', { count: i + 1 })}:{' '}
        <b>
          {t(
            `received_requests.follow_up_treatments.${v.post_processing_method}`,
            v.post_processing_method,
          )}
        </b>
        {['custom', 'painted', 'color_dyed'].includes(
          v.post_processing_method,
        ) && (
          <>
            ,{' '}
            {t(
              `received_requests.follow_up_treatments.description.${v.post_processing_method}.text`,
            )}
            : <b>{v.description}</b>
          </>
        )}
      </div>
    ),
  )

  return (
    <ConfigProvider
      theme={{
        token: {
          colorSplit: '#fff',
        },
      }}
    >
      <Row style={{ marginBottom: '30px' }} gutter={50}>
        <Col span={8}>
          <div>
            <h4>{t('received_requests.follow_up_treatments.title')}</h4>
            <List
              size="small"
              dataSource={dataSource}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
        </Col>
        <Col span={16}>
          <h4>{t('received_requests.extended_specifications')}</h4>
          <Row gutter={50} style={{ paddingTop: '8px' }}>
            <Col span={12}>
              <p>{t('received_requests.special_specifications')}:</p>
              <p>{orderPart.extra_specifications}</p>
            </Col>
            <Col span={12}>
              <p>
                <Flex justify="space-between">
                  {t('received_requests.maintain_aligment')}{' '}
                  <Switch
                    size="small"
                    checked={orderPart.maintain_alignment}
                    disabled
                  />
                </Flex>
              </p>
              <OrderTechnicalDrawingDownload orderPart={orderPart} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ConfigProvider>
  )
}

export default FollowUpTreatmentsView
