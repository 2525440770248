import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LoadUserCentricsScript = ({
  settingsId = process.env.REACT_APP_USER_CENTRICS_SETTINGS_ID,
  children,
}) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const scriptId = 'usercentrics-cmp'
    const existingScript = document.getElementById(scriptId)

    if (!existingScript && settingsId) {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'
      script.async = true
      script.setAttribute('data-settings-id', settingsId)
      script.setAttribute('data-language', i18n.resolvedLanguage || 'en')
      document.body.appendChild(script)

      script.onerror = () => {
        console.error('Error loading UserCentrics script.')
      }
    }

    const updateLanguage = async () => {
      if (window.UC_UI && window.UC_UI.updateLanguage) {
        await window.UC_UI.updateLanguage(i18n.resolvedLanguage || 'en')
      }
    }
    updateLanguage()

    return () => {
      const script = document.getElementById(scriptId)
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [i18n.resolvedLanguage, settingsId])

  return <>{children}</>
}

export default LoadUserCentricsScript
