import styled from 'styled-components'

export const StyledTruncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledTruncateClamp = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
