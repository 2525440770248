import { useState } from 'react'
import { Button, Divider, Flex, Modal, Popconfirm } from 'antd'
import Tooltip from '$components/Tooltip'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ORDERPARTS_STORAGE_KEY } from '$constants'
import {
  StyledBookCardTop,
  StyledRibbon,
  StyledRoundButton,
  StyledServicecard,
  StyleBookButton,
} from './styledComponents'
import useServiceKPI from '$utils/useServiceKpis'

const getDisabledStyle = (disabled) =>
  disabled ? { color: 'rgba(0, 0, 0, 0.25)' } : {}

const ServiceButton = ({
  service,
  demoflag = false,
  buttonToggle,
  disabled,
  isRowSelected,
  continueOrder,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const kpiData = useServiceKPI(service)
  const { t } = useTranslation()

  const handleIconClick = (e) => {
    e.stopPropagation()
  }

  const openModal = () => {
    setModalContent(service)
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const handleButtonClick = (e, clickedService) => {
    e.stopPropagation()
    if (!disabled) {
      buttonToggle(clickedService)
    }
  }

  const popConfirmDisabled =
    !isRowSelected || !localStorage.getItem(ORDERPARTS_STORAGE_KEY)

  const renderActionButtons = () => {
    // If the service is a combined one (has subservices)
    if (service.subservices?.length) {
      return (
        <Flex justify="flex-end" gap="16px" style={{ width: '100%' }}>
          {service.subservices.map((sub) => (
            <StyleBookButton
              key={sub.id}
              type="dashed"
              size="small"
              disabled={disabled}
              style={!isRowSelected ? { opacity: 0.5 } : {}}
              onClick={(e) => handleButtonClick(e, sub)}
              block
            >
              <span className="shortened-text">{t(sub.servicename)}</span>
              <ArrowRightOutlined />
            </StyleBookButton>
          ))}
        </Flex>
      )
    }

    // If it's a "Order Part" service
    if (service.id === '63dcfb10f81b69c6d3a7c42f') {
      return (
        <Flex justify="space-between">
          {localStorage.getItem(ORDERPARTS_STORAGE_KEY) ? (
            <Tooltip placement="topLeft" title={t('continue_tooltip')}>
              <Button onClick={(e) => continueOrder(e, service)} size="small">
                {t('continue')}
              </Button>
            </Tooltip>
          ) : (
            <div></div>
          )}
          <Popconfirm
            title={t('my_orders.repeat_order_popconfirm_title')}
            description={t('my_orders.repeat_order_popconfirm_description')}
            onConfirm={(e) => handleButtonClick(e, service)}
            okText={t('Yes')}
            cancelText={t('No')}
            disabled={popConfirmDisabled}
          >
            <Button
              type="dashed"
              size="small"
              disabled={disabled}
              style={!isRowSelected ? { opacity: 0.5 } : {}}
              onClick={
                popConfirmDisabled ? (e) => handleButtonClick(e, service) : null
              }
            >
              {t('Book service')}
              <ArrowRightOutlined />
            </Button>
          </Popconfirm>
        </Flex>
      )
    }

    // Default for every other service
    return (
      <Flex justify="flex-end">
        <Button
          type="dashed"
          size="small"
          disabled={disabled}
          style={!isRowSelected ? { opacity: 0.5 } : {}}
          onClick={(e) => handleButtonClick(e, service)}
        >
          {t('Book service')} <ArrowRightOutlined />
        </Button>
      </Flex>
    )
  }

  return (
    <>
      {demoflag && !disabled && (
        <StyledRibbon>
          <div className="ribbon ribbon-top-left">
            <span>demo</span>
          </div>
        </StyledRibbon>
      )}

      <StyledServicecard
        disabled={disabled}
        $isMetaservice={!!service.subservices?.length}
      >
        <StyledBookCardTop>
          <StyledRoundButton
            size="large"
            type="primary"
            shape="circle"
            icon={service.icon}
            disabled={disabled}
            onClick={handleIconClick}
          />
          <div>
            <h3 style={{ margin: '0 0 6px 0', ...getDisabledStyle(disabled) }}>
              {t(service.servicename)}
              <InfoCircleOutlined className="info_icon" onClick={openModal} />
            </h3>
            <div style={getDisabledStyle(disabled)}>{kpiData}</div>
          </div>
        </StyledBookCardTop>

        <Divider style={{ margin: '16px 0' }} />

        {renderActionButtons()}
      </StyledServicecard>

      <Modal
        title={t(modalContent.servicename)}
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <p>{t(modalContent.serviceInfo)}</p>

        {modalContent.subservices && (
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {modalContent.subservices.map((sub) => (
              <li key={sub.id} style={{ marginBottom: '16px' }}>
                <b>{t(sub.servicename)}:</b> {t(sub.serviceInfo)}
              </li>
            ))}
          </ul>
        )}
      </Modal>
    </>
  )
}

export default ServiceButton
