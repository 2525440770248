import { useState } from 'react'
import { Button, Checkbox, Col, Divider, Input, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import ShortUniqueId from 'short-unique-id'
import { getPartStl, getTechnicalDrawing } from '$api/client'
import AddressFormItems from '$components/AddressFormItems'
import Form from '$components/Form'
import useAvailableMaterials from '$utils/useAvailableMaterials'
import { useCompany } from '$context/user'
import { StyledForm } from '../../Defaultwizard/styledComponents'
import OrderPartsTable, { updateMainAmount } from './OrderPartsTable'
import { ORDERPARTS_STORAGE_KEY } from '$constants'

const { TextArea } = Input

const StepTwoConfigOrderPart = ({
  bookableParts,
  setConfigState,
  setAccumulatedFormData,
  setOrderPartsListData,
  initialLocalState, // Replacing orderToRepeat
  wizardData,
  setConfigFormData,
}) => {
  const uid = new ShortUniqueId({ length: 8, dictionary: 'alphanum_upper' })
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const companyData = useCompany()

  // Derive whether billing is the same as delivery based on initialLocalState, or default to 'true'
  const [isBillingSameAsDeliveryAddress, setIsBillingSameAsDeliveryAddress] =
    useState(() => {
      if (!initialLocalState) return true
      // Compare delivery and billing addresses (very naive check—adjust as needed)
      return (
        JSON.stringify(initialLocalState?.delivery_address) ===
        JSON.stringify(initialLocalState?.billing_address)
      )
    })

  // For showing "loading" states per part as you do your getPartStl calls
  const [processingParts, setProcessingParts] = useState([])

  // Generate an orderNumber here for new orders
  const orderNumber = uid.rnd()

  // Might be used for your "material" selection
  const availableMaterials = useAvailableMaterials()

  const configOrderPartInitialValues = bookableParts.bookableParts.reduce(
    (acc, part) => {
      acc[part.id] = {
        maintain_alignment: false,
        extra_specifications: null,
      }
      return acc
    },
    {},
  )
  const persistToLocalStorage = (form) => {
    const formValues = form.getFieldsValue()
    const mergedData = {
      ...wizardData,
      configFormData: formValues,
    }
    localStorage.setItem(ORDERPARTS_STORAGE_KEY, JSON.stringify(mergedData))
  }

  const initialValuesOrderToRepeat = (
    companyData,
    configOrderPartInitialValues,
    initialLocalState,
  ) => {
    return {
      delivery_address: { ...companyData },
      billing_address_same_as_delivery: true,
      billing_address: { ...companyData },
      config_order_part: configOrderPartInitialValues,
      ...(initialLocalState?.list_of_orderparts?.reduce(
        (acc, orderpart) => {
          if (
            orderpart?.part?.db_id_client &&
            initialLocalState?.main_order?.orderpart_quantity?.[orderpart.id]
          ) {
            acc.amounts[orderpart.part.db_id_client] =
              initialLocalState.main_order.orderpart_quantity[orderpart.id]
          }
          if (orderpart?.part?.db_id_client && orderpart?.material) {
            acc.materials[orderpart.part.db_id_client] = orderpart.material
          }

          return acc
        },
        { amounts: {}, materials: {} },
      ) || {}),
    }
  }

  const onFinish = async (values) => {
    setConfigFormData(values)
    // set status to "finished"
    setProcessingParts(bookableParts.bookableParts.map((part) => part.id))
    let accumulatedSchema = []
    const configOrderPart = {
      ...configOrderPartInitialValues,
      ...(values?.config_order_part || {}),
    }
    // post to endpoints
    const promises = bookableParts.bookableParts.map(async (part) => {
      const blob = await getPartStl(part.id)
      let technicalDrawing = null
      if (part.technical_drawing !== null)
        technicalDrawing = await getTechnicalDrawing(part.id)

      // select relevant attributes from part
      const {
        id,
        name,
        id_part_client,
        company,
        attributes,
        float_attributes,
        status_attributes,
        dwh_materials,
      } = part

      const schema = {
        part: {
          id,
          name,
          id_part_client,
          company,
          attributes,
          float_attributes,
          status_attributes,
          dwh_materials,
          db_id_client: part.id, // your client part id
          files: [], // stl or other files
        },
        amount: values.amounts[part.id],
        material_id: values.materials[part.id],
        stl_file: blob, // additionally pass file to be uploaded to consolidation
        technical_drawing: technicalDrawing,
        maintain_alignment: configOrderPart[part.id].maintain_alignment,
        extra_specifications: configOrderPart[part.id].extra_specifications,
        selected_post_processing_methods: (
          values?.selected_post_processing_methods?.[part.id] || []
        ).filter((entry) => entry.post_processing_method),
      }

      // Once finished, remove from processing
      setProcessingParts((prev) =>
        prev.filter((processingId) => processingId !== part.id),
      )

      return schema
    })

    accumulatedSchema = await Promise.all(promises || [])
    setAccumulatedFormData(accumulatedSchema)

    // Build up the entire order JSON structure to pass onward
    setOrderPartsListData({
      order_number: orderNumber,
      client_reference: values.client_reference,
      client_note: values.client_note,
      delivery_address: values.delivery_address,
      billing_address: isBillingSameAsDeliveryAddress
        ? values.delivery_address
        : values.billing_address,
      main_order: {
        orderpart_quantity: values.amounts,
        allow_partial_delivery: values.allow_partial_delivery,
        allow_other_suppliers: values.allow_other_suppliers,
        allow_subcontractors: values.allow_subcontractors,
        desired_delivery_date: values.desired_delivery_date,
      },
      // In case the user adds suborders in your UI
      list_of_suborders: values.subOrders
        ?.filter((subOrder) => subOrder !== undefined)
        .map((subOrder) => ({
          orderpart_quantity: subOrder.amounts,
          allow_partial_delivery: subOrder.allow_partial_delivery,
          allow_other_suppliers: subOrder.allow_other_suppliers,
          allow_subcontractors: subOrder.allow_subcontractors,
          desired_delivery_date: subOrder.desired_delivery_date,
        })),
    })

    // If all parts are done (processingParts is empty), set some completion state
    if (!processingParts.length) {
      setConfigState(true)
    }
  }

  return (
    <>
      <StyledForm
        name="orderPartsForm"
        form={form}
        onFinish={onFinish}
        wrapperCol={{ span: 17 }}
        labelCol={{ span: 5 }}
        autoComplete="off"
        layout="horizontal"
        initialValues={
          wizardData.configFormData
            ? wizardData.configFormData
            : initialValuesOrderToRepeat(
                companyData,
                configOrderPartInitialValues,
                initialLocalState,
              )
        }
        // Update the amounts in the form state on user changes
        onValuesChange={(changedValues, allValues) => {
          updateMainAmount(form, changedValues, allValues)
          persistToLocalStorage(form)
        }}
      >
        <Row className="row">
          <h2 style={{ margin: '50px 0' }}>
            {t('service.order_part.step_config_title')}
          </h2>
          <div style={{ marginRight: '50px' }}>
            {bookableParts && (
              <OrderPartsTable
                parts={bookableParts.bookableParts}
                materials={availableMaterials}
                processingParts={processingParts}
                wizardData={wizardData}
                form={form}
              />
            )}
          </div>
        </Row>
        <Divider />
        <Row>
          <Col flex="1 0 25%" className="formcolumn">
            <h4 style={{ padding: '15px 0' }}>
              {t('service.order_part.order_address.delivery_address')}
            </h4>
            <AddressFormItems prefix="delivery_address" />
            <Divider />
            <Form.Item style={{ margin: '10px 0 0 126px' }}>
              <Checkbox
                checked={isBillingSameAsDeliveryAddress}
                onChange={(e) => {
                  setIsBillingSameAsDeliveryAddress(e.target.checked)
                }}
              >
                {t('service.order_part.order_address.same_as_delivery_address')}
              </Checkbox>
            </Form.Item>
            {!isBillingSameAsDeliveryAddress && (
              <>
                <h4 style={{ marginTop: '-26px', paddingBottom: '15px' }}>
                  {t('service.order_part.order_address.billing_address')}
                </h4>
                <AddressFormItems prefix="billing_address" />
              </>
            )}
          </Col>
          <Divider
            type="vertical"
            style={{ height: 'unset', margin: '60px 40px' }}
          />
          <Col flex="1 0 25%" className="formcolumn">
            <h4 style={{ padding: '15px 0' }}>{t('Additional information')}</h4>
            <Form.Item
              name="client_reference"
              label={t('service.order_part.client_reference')}
            >
              <Input
                placeholder={t(
                  'service.order_part.client_reference_placeholder',
                )}
              />
            </Form.Item>
            <Divider />
            <h4 style={{ padding: '15px 0' }}>
              {t('service.order_part.specifications')}
            </h4>
            <Form.Item
              name="client_note"
              label={t('service.order_part.additional_notes')}
            >
              <TextArea
                placeholder={t(
                  'service.order_part.additional_notes_placeholder',
                )}
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Space>
        </Form.Item>
      </StyledForm>
      <Divider />
    </>
  )
}

export default StepTwoConfigOrderPart
