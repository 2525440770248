import { theme } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { UilPaperclip } from '@iconscout/react-unicons'
import { getTechnicalDrawing } from '$api/client'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import Tooltip from '$components/Tooltip'

const { useToken } = theme

const TechnicalDrawingDownload = ({ record }) => {
  const { token } = useToken()
  const { t } = useTranslation()

  const { data: technicalDrawing } = useQuery({
    queryKey: ['technicalDrawing', record.id],
    queryFn: ({ queryKey: [, id] }) => getTechnicalDrawing(id),
    enabled: !!record.technical_drawing,
  })

  const downloadTechnicalDrawing = () => {
    const blob = new Blob([technicalDrawing.data], {
      type: technicalDrawing.type,
    })
    const blobUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = technicalDrawing.filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(blobUrl)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          margin: '20px 0 9px 0',
        }}
      >
        <p style={{ margin: 0 }}>{t('service.order_part.technical_drawing')}</p>
        <Tooltip title={t('service.order_part.technical_drawing_tooltip')}>
          <InfoCircleOutlined style={{ fontSize: '14px', cursor: 'pointer' }} />
        </Tooltip>
      </div>

      {technicalDrawing ? (
        <>
          <UilPaperclip
            size="14"
            color={token.colorText}
            style={{ margin: '0px 5px -3px 0px' }}
          />
          <a onClick={downloadTechnicalDrawing}>{technicalDrawing.filename}</a>
        </>
      ) : (
        <p style={{ color: token.colorTextDisabled }}>
          {t('service.order_part.technical_drawing_not_available')}
        </p>
      )}
    </>
  )
}

export default TechnicalDrawingDownload
